/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useQuery } from "@tanstack/react-query";
import { useRecoilState } from "recoil";
import Swal from "sweetalert2";
import processflowStageService from "../../../services/processflow-stage.service";
import processflowService from "../../../services/processflow.service";
import positionAtom, { Position } from "../../../atoms/positionAtom";
import useStepValidation from "./use-step-validation";
import progressDataAtom from "../../../atoms/progressDataAtom";
import { ProcessFlowProgressData } from "../../../typings/api/processflow-progress-data";
import groupAtom from "../../../atoms/groupAtom";
import { useEffect, useState } from "react";

export default function useNavigateProcess(incomingGroupId: number) {
    const [position, setPosition] = useRecoilState<Position>(positionAtom);
    const [processFlowGroupId, setProcessFlowGroupId] = useRecoilState<number>(groupAtom);
    const [checkStepValidation] = useStepValidation();
    const [progressData] = useRecoilState<ProcessFlowProgressData>(progressDataAtom);
    const [initialSetup, setInitialSetup] = useState(false);

    async function setGroupId(groupId: number) {
        const result = await processflowService.getAllByOrderForGroupWithRuleGroupsAndRules(groupId);
        if (result) {
            setProcessFlowGroupId(groupId);
            setStageId(result.data[0].stage);
            setStepId(result.data[0].id);
            // setPosition((c: Position) => { ...c, stepId: result.data[0].id ?? 7 });
            // setPosition({ stage: Number(result.data[0].stage), step: Number(result.data[0].id) });
            console.error(result.data);
        }
    }

    useEffect(() => {
        if (incomingGroupId && !initialSetup) {
            setProcessFlowGroupId(incomingGroupId);
            setInitialSetup(true);
        }
    }, [incomingGroupId, initialSetup, setProcessFlowGroupId]);

    const entriesQuery = useQuery(
        processflowService.getCacheKey({
            currentStage: position.stageId?.toString(),
            processFlowGroup: processFlowGroupId.toString(),
            relation: "relation",
            include: "businessRules",
            identifier: "1",
        }),
        async () => {
            let stage = position.stageId;
            if (stage === -1) {
                const response = await processflowService.getAllByOrderForGroupWithRuleGroupsAndRules(
                    Number(processFlowGroupId),
                );
                if (response) {
                    stage = response.data[0].stage;
                }
                // return [];
            }

            const response = await processflowService.getAllByOrderForStageAndGroup(stage, Number(processFlowGroupId));
            if (response) {
                return response.data;
            }
        },
    );

    const allStageQuery = useQuery(["processflow-stages", "getAllByGroupId", processFlowGroupId], async () => {
        const response = await processflowStageService.getAllByGroupIdIncludingRuleGroups(processFlowGroupId);
        if (response) {
            return response.data;
        } else {
            Swal.fire({
                title: "Error",
                text: "There was an error getting the stages for this process flow group",
                icon: "error",
                confirmButtonText: "OK",
            });
            return [];
        }
    });

    const getStageFromStepId = async (stepId: number) => {
        if (!(position.stepId > 0)) {
            console.error("No step id");
            return;
        }
        if (allStageQuery.isLoading) {
            console.log("Stage query is loading");
            return;
        }

        const responseStep = await processflowService.getOne(Number(position.stepId));
        if (responseStep) {
            const step = responseStep.data;
            const stage = await allStageQuery.data?.find((stage) => stage.id === step.stage);
            return stage;
        }
        // const stage = await allStageQuery
        return;
    };

    // const entriesQuery = useQuery(processflowService.getCacheKey({ currentStep: position.stepId?.toString(), processFlowGroup: processFlowGroupId.toString(), relation: "relation", include: "businessRules" }), async () => {

    //     const responseStep = await processflowService.getOne(Number(position.stepId));
    //     if (responseStep) {
    //         const step = responseStep.data;
    //         const response = await processflowService.getAllByOrderForStageAndGroup(step.stage, Number(processFlowGroupId));
    //         if (response) {
    //             // console.info({ response })
    //             return response.data;
    //         }
    //     }

    // });

    /**
     * Queries the process flow entries for the current stage and group.
     *
     * @param {number} currentStage - the current stage of the process flow
     * @param {string} id - the id of the group that the process flow is for
     * @returns {ProcessFlow[]} - the process flow entries for the current stage and group
     */
    const allEntriesForStageAndGroupQuery = useQuery(
        processflowService.getCacheKey({
            currentStage: position.stageId?.toString(),
            processFlowGroup: processFlowGroupId.toString(),
            relation: "relation",
            include: "businessRules",
            identifier: "2",
        }),
        async () => {
            if (position.stageId === -1) {
                console.error("No current stage");
                return;
            }

            const response = await processflowService.getAllByOrderForStageAndGroup(
                position.stageId,
                processFlowGroupId,
            );
            if (response && response.data) {
                return response.data;
            }
        },
    );

    async function getStepById(id: number) {
        let fullStep = allEntriesForStageAndGroupQuery?.data?.find((step) => step.id === id);
        if (!fullStep) {
            fullStep = entriesQuery?.data?.find((step) => step.id === id);
        }
        if (!fullStep) {
            console.error("No step found for id", id, entriesQuery?.data, allEntriesForStageAndGroupQuery?.data);
            // alert("No step found for id");
        }

        return fullStep;
    }

    /**
     *
     * @param ignoreIds - an array of ids to ignore when checking if the current step is the last step
     * We will find the highest order stepo and then return that.
     */
    function nextStep(ignoreIds: number[] = []) {
        // TODO: this should only be called if the current step is valid it currently has no rules to compare against.

        // TODO:TODO: Combine entries and processflows
        // const entry = getStepById(position.stepId);
        // if (!entry) {
        //     console.error("No entry found for step id", position.stepId);
        //     return false;
        // }

        // const entry = JSON.parse(allEntriesForStageAndGroupQuery?.data?.[0].entries ?? "");
        // const results = checkStepValidation(entry,  progressData);
        // console.log("Validation results: ", results);

        const currentIndex = (allEntriesForStageAndGroupQuery?.data ?? []).findIndex(
            (entry: any) => entry.id === position.stepId,
        );

        // let found
        // TODO: loop through the steps and find the next one that is valid (or the end_)
        if (currentIndex > -1) {
            const nextStep = (allEntriesForStageAndGroupQuery?.data ?? [])[currentIndex + 1]?.id;
            if (nextStep) {
                setStepId(nextStep);
            } else {
                throw new Error("No next step");
            }
        }
    }

    /**
     * Moves to the previous step.
     */
    function previousStep() {
        // const entry = JSON.parse(allEntriesForStageAndGroupQuery?.data?.[0].entries ?? "");
        // const results = checkStepValidation(entry, progressData);
        const currentIndex = (allEntriesForStageAndGroupQuery?.data ?? []).findIndex(
            (entry: any) => entry.id === position.stepId,
        );
        if (currentIndex > -1) {
            const previousStep = (allEntriesForStageAndGroupQuery?.data ?? [])[currentIndex - 1]?.id;
            if (previousStep) {
                setStepId(previousStep);
            } else {
                throw new Error("No previous step");
            }
        } else {
            previousStage();
        }
    }

    async function setStepId(stepId?: number) {
        if (stepId) {
            // Check that the step ID is valid
            const x = 0;
            while (isSuccess) {
                await new Promise((resolve) => setTimeout(resolve, 100));
            }
            const step = await getStepById(stepId);
            if (step) {
                setPosition((c: Position) => ({ ...c, stepId }));
            } else {
                Swal.fire({
                    title: "Error",
                    text:
                        "There was an error getting the step for this process flow group " +
                        processFlowGroupId +
                        " and step id " +
                        stepId,
                    icon: "error",
                    confirmButtonText: "OK",
                });
            }
        }
    }

    function getStageById(id: number) {
        return allStageQuery.data?.find((stage) => stage.id === id);
    }

    function nextStage(): boolean {
        const currentStageOrder = getStageById(position.stageId)?.order ?? 1;
        if (!(currentStageOrder > 0)) {
            Swal.fire({
                title: "Error",
                text: "There was an error getting the current stage order",
                icon: "error",
                confirmButtonText: "OK",
            });
            return false;
        }

        const sortedStages =
            allStageQuery.data?.sort((a, b) => {
                return (a.order ?? 0) - (b?.order ?? 0);
            }) ?? [];
        const currentStageIndex = sortedStages.findIndex((x) => x.id === position.stageId);
        const nextStage = sortedStages[currentStageIndex + 1];
        if (nextStage) {
            setStageId(nextStage.id);
            return true;
        } else {
            Swal.fire({
                title: "Error",
                text: "There was an error getting the next stage",
                icon: "error",
                confirmButtonText: "OK",
            });
            return false;
        }
    }

    /**
     * Moves from the current stage to the previous stage.
     * @returns None
     */
    async function previousStage(): Promise<boolean> {
        if (isSuccess) {
            await new Promise((resolve) => setTimeout(resolve, 100));
        }
        const currentStageOrder = getStageById(position.stageId)?.order ?? 1;
        if (!(currentStageOrder > 0)) {
            Swal.fire({
                title: "Error",
                text: "There was an error getting the current stage order",
                icon: "error",
                confirmButtonText: "OK",
            });
            return false;
        }

        const sortedStages =
            allStageQuery.data?.sort((a, b) => {
                return (a.order ?? 0) - (b?.order ?? 0);
            }) ?? [];
        const currentStageIndex = sortedStages.findIndex((x) => x.id === position.stageId);
        const previousStage = sortedStages[currentStageIndex - 1];
        if (previousStage) {
            const response = await processflowService.getAllByOrderForStageAndGroup(
                previousStage.id!,
                processFlowGroupId,
            );
            const steps = response?.data || [];
            const lastStep = steps[steps.length - 1];
            // set stage and step at the same time to prevent "flashing" on the UI.
            // TODO: This should be the last ste that is visible to the user.
            setPosition((c: Position) => ({
                ...c,
                stageId: previousStage.id!,
                ...(lastStep ? { stepId: lastStep.id } : {}),
            }));
            return true;
        } else {
            Swal.fire({
                title: "Error",
                text: "There was an error getting the previous stage",
                icon: "error",
                confirmButtonText: "OK",
            });
            return false;
        }
    }

    async function setStageId(stageId?: number) {
        if (stageId) {
            // Check that the stage ID is valid
            const stage = getStageById(stageId);
            if (stage) {
                const response = await processflowService.getAllByOrderForStageAndGroup(
                    stageId,
                    Number(processFlowGroupId),
                );
                if (response) {
                    // Sort by order
                    const sorted = response.data.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
                    // Find the first step in that stage
                    const firstStep = sorted[0];
                    if (firstStep?.id) {
                        setPosition({ stageId, stepId: firstStep.id });
                    }
                }
            }
        }
    }

    const isSuccess = allStageQuery.isSuccess && allEntriesForStageAndGroupQuery.isSuccess && entriesQuery.isSuccess;

    return { isSuccess, setStageId, setStepId, nextStep, previousStep, nextStage, previousStage, setGroupId };
}
