import { bytesToSize, formatEmail, formatPhonenumber } from "../sections/utilities/formatters";
import { NamedColorspace } from "@textea/json-viewer";
import { JsonViewer } from "@textea/json-viewer";
import { useQuery } from "@tanstack/react-query";
import Loader2 from "../sections/utilities/Loader2";
import usersService from "../services/users.service";
import companyService from "../services/company.service";
import processflowGroupService from "../services/processflow-group.service";
import businessRuleTypeService from "../services/business-rule-type.service";
import processflowService from "../services/processflow.service";
import React, { Fragment, useCallback, useState, useEffect, useMemo } from "react";
import roleGroupService from "../services/role-group.service";
import defaultSourcesService from "../services/default-sources.service";
import ButtonNeoGen, { ButtonNeoGenTypes } from "./button-neogen";
import interactionTypesService from "../services/interaction-types.service";
import OptionsDropDown from "./options-dropdown";
import SelectNeoGen from "./select-neogen";
import { Entry } from "./select-neogen";
import PrintPre from "./print-pre";
import { stringify } from "querystring";
import SelectNeoGenSearchable from "./select-neogen-searchable";
import Filter from "./filter";
import clientStatusService from "../services/client-status.service";
import { Search, Option, Detail } from "searchpal";
import Avatar from "react-avatar";
import { ClearERCUser } from "../typings/api/clear-erc-user";
import FindField from "./table-components/find-field";
import AutoMatchModal from "../sections/admin/tools/modals/auto-match-modal";
import { ProcessFlow } from "../typings/api/processflow";

const camel2title = (camelCase: string) =>
    camelCase
        .replace(/([A-Z])/g, (match) => ` ${match}`)
        .replace(/^./, (match) => match.toUpperCase())
        .trim();

function compare(a: any, b: any) {
    if (a.name?.toLowerCase() < b.name?.toLowerCase()) {
        return -1;
    }
    if (a.name?.toLowerCase() > b.name?.toLowerCase()) {
        return 1;
    }
    return 0;
}

export const solarized: NamedColorspace = {
    scheme: "Solarized Light",
    author: "Ethan Schoonover (modified by aramisgithub)",
    base00: "fdf6e3",
    base01: "eee8d5",
    base02: "93a1a1",
    base03: "839496",
    base04: "657b83",
    base05: "586e75",
    base06: "073642",
    base07: "002b36",
    base08: "dc322f",
    base09: "cb4b16",
    base0A: "b58900",
    base0B: "859900",
    base0C: "2aa198",
    base0D: "268bd2",
    base0E: "6c71c4",
    base0F: "d33682",
};

export const summerfruitDark: NamedColorspace = {
    scheme: "Summerfruit Dark",
    author: "Christopher Corley (http://christop.club/)",
    base00: "151515",
    base01: "202020",
    base02: "303030",
    base03: "505050",
    base04: "B0B0B0",
    base05: "D0D0D0",
    base06: "E0E0E0",
    base07: "FFFFFF",
    base08: "FF0086",
    base09: "FD8900",
    base0A: "ABA800",
    base0B: "00C918",
    base0C: "1FAAAA",
    base0D: "3777E6",
    base0E: "AD00A1",
    base0F: "CC6633",
};

function inIframe() {
    if (window.location.search === "?inIframe=1") {
        return true;
    }
    try {
        document.body.classList.add("iframe");
        return window.self !== window.top;
    } catch (e) {
        document.body.classList.add("inIframe");
        return true;
    }
}
function getDarkMode() {
    const currentLocalStorageTheme = inIframe() ? "light" : localStorage.getItem("theme");
    if (currentLocalStorageTheme !== null) {
        if (currentLocalStorageTheme === "dark") {
            return true;
        } else {
            return false;
        }
    } else {
        // Couldn't find theme in local storage
        // console.error("Theme not stored");
        if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
            return true;
        } else {
            return false;
        }
    }
}

export interface SearcherOptions {
    id?: string | number;
    name?: string | null;
    value?: string;
    type?: string;
    detail?: string;
    icon?: string;
}

export default function TableNeogen(props: TableNeogenProps) {
    const [openFindField, setOpenField] = useState(false);
    const [id, setId] = useState("");
    const [value, setValue] = useState<any>("");
    const [entryBeingEdited, setEntryBeingEdited] = useState<any>(null);
    const [showAutoMatchModal, setShowAutoMatchModal] = useState(false);
    const [autoMatchSuggestions, setAutoMatchSuggestions] = useState<ProcessFlow[]>([]);
    const [autoMatchTo, setAutoMatchTo] = useState<ProcessFlow>();
    const [searcherClick, setSearcherClick] = useState(() => (data: any) => {
        console.trace("onClick not set");
    });
    const [options, setOptions] = useState<SearcherOptions[] | undefined>([]);

    const roleGroupQuery = useQuery(
        ["roleGroups"],
        async () => {
            const response = await roleGroupService.getAll();
            if (response) {
                return response.data;
            }
            return [];
        },
        {
            enabled: (props.formatters?.filter((e) => e?.type === "RoleGroup")?.length ?? 0) > 0,
        },
    );
    const statusQuery = useQuery(["clientStatuses"], async () => {
        const response = await clientStatusService.getAll();
        return response?.data || [];
    });

    const messageTypesQuery = useQuery(
        ["messageTypes"],
        async () => {
            const response = await interactionTypesService.getAll();
            if (response) {
                return response.data;
            }
            return [];
        },
        {
            enabled: (props.formatters?.filter((e) => e?.type === "MessageType")?.length ?? 0) > 0,
        },
    );
    const defaultSourcesQuery = useQuery(
        ["defaultSources"],
        async () => {
            const response = await defaultSourcesService.getAll();
            if (response) {
                return response.data;
            }
            return [];
        },
        {
            enabled: (props.formatters?.filter((e) => e?.type === "DefaultSource")?.length ?? 0) > 0,
        },
    );
    const groupQuery = useQuery(
        ["groups"],
        async () => {
            const response = await processflowGroupService.getAll();
            if (response) {
                return response.data;
            }
            return [];
        },
        {
            enabled: (props.formatters?.filter((e) => e?.type === "ProcessFlowGroup")?.length ?? 0) > 0,
        },
    );
    const processflowQuery = useQuery(
        ["processFlow"],
        async () => {
            const response = await processflowService.getAll();
            if (response) {
                return response.data;
            }
            return [];
        },
        {
            enabled:
                (props.formatters?.filter((e) => e?.type === "Processflow" || e?.type === "Searcher")?.length ?? 0) > 0,
        },
    );
    const usersQuery = useQuery(
        ["users"],
        async () => {
            const response = await usersService.getAll();
            if (response) {
                return response.data;
            }
            return [];
        },
        {
            enabled: (props.formatters?.filter((e) => e?.type === "User")?.length ?? 0) > 0,
        },
    );

    const ruleTypeQuery = useQuery(
        ["business-rule-types2"],
        async () => {
            const response = await businessRuleTypeService.getAll();
            if (response) {
                return response.data;
            } else {
                return [];
            }
        },
        {
            enabled: (props.formatters?.filter((e) => e?.type === "RuleType")?.length ?? 0) > 0,
        },
    );

    const companyQuery = useQuery(
        ["companies"],
        async () => {
            const response = await companyService.getAll();
            if (response) {
                return response.data.sort(compare);
            }
        },
        {
            enabled: (props.formatters?.filter((e) => e?.type === "Company")?.length ?? 0) > 0,
        },
    );
    const processFlowFieldsQuery = useQuery(
        ["processFlowFields", "group", 7],
        async () => {
            const response = await processflowService.getAllByGroupIdWithRuleGroupsAndRules(7);
            if (response) {
                // console.error(response.data);
                return response.data;
            }
            return [];
        },
        {
            enabled: (props.formatters?.filter((e) => e?.type === "Searcher")?.length ?? 0) > 0,
        },
    );

    const [entries, setEntries] = useState<any[]>([]);
    const [px, setPx] = useState(3);
    const [py, setPy] = useState(3.5);
    const [pytd, setPytd] = useState(4);

    const [headers, setHeaders] = useState<string[]>([]);
    useEffect(() => {
        if (props.compact) {
            setPx(1);
            setPy(0);
            setPytd(1);
        }
    }, [props.compact]);

    // const sugs = useMemo(() => {

    // }, [processflowQuery.data]);

    useEffect(() => {
        if (!props.entries) {
            return;
        } else {
            if (!props.entries[0]) {
                return;
            }
        }
        const entries = JSON.parse(JSON.stringify(props.entries));
        const newEntries = entries.map((entry: any) => {
            if (props.ignoreFields) {
                props.ignoreFields.forEach((i) => {
                    try {
                        delete entry[i];
                    } catch (x) {
                        console.error(x);
                    }
                });
            }

            if (entry) {
                Object.entries(entry).forEach((e2) => {
                    const fo = props.formatters?.find((f) => {
                        return f.field === e2[0];
                    });
                    if (fo?.customOptions) {
                        fo.options = [];
                        fo.customOptions(e2, entry, fo).forEach((e: any) => {
                            fo.options?.push(e);
                        });
                    }
                    if (fo) {
                        switch (fo.type) {
                            case "Searcher":
                                entry[e2[0]] = (
                                    <>
                                        <div>
                                            <div className="grid grid-cols-3 gap-5">
                                                {/* <div className="mb-3">{e2[1] as string}</div> */}
                                                <span className="text-clip overflow-hidden ">
                                                    {e2[1] as unknown as any}
                                                </span>
                                                <ButtonNeoGen
                                                    disabled={entry.ourSource.props.value < 1}
                                                    size="xs"
                                                    type={e2[1] ? "info" : "secondary"}
                                                    className=" text-clip overflow-hidden"
                                                    onClick={() => {
                                                        setEntryBeingEdited(entry);
                                                        // alert("clicked");
                                                        // console.log(entry.ourSource.props.value);
                                                        switch (entry.ourSource.props.value) {
                                                            case 0:
                                                                setOptions(
                                                                    processFlowFieldsQuery.data?.map((e) => {
                                                                        return {
                                                                            id: e.id,
                                                                            name: e.name,
                                                                        };
                                                                    }),
                                                                );
                                                                break;
                                                            case 1:
                                                                setOptions(
                                                                    processFlowFieldsQuery.data?.map((e) => {
                                                                        return {
                                                                            id: e.id,
                                                                            name: e.name,
                                                                        };
                                                                    }),
                                                                );
                                                                break;
                                                            case 2:
                                                                setOptions(
                                                                    usersQuery.data?.map((e) => {
                                                                        return {
                                                                            id: e.id,
                                                                            name: e.name,
                                                                        };
                                                                    }),
                                                                );
                                                                break;
                                                            default:
                                                                break;
                                                        }

                                                        setOpenField(true);
                                                        if (typeof fo.onClickSelection !== undefined) {
                                                            setSearcherClick(() => (selected: any) => {
                                                                alert("zxczxc");
                                                                console.error({ selected, id: e2[1], entry });
                                                                fo.onClickSelection?.({
                                                                    selected,
                                                                    id: (e2[1] as number) ?? -1,
                                                                    entry,
                                                                });
                                                                return true;
                                                            });
                                                        } else {
                                                            alert("No onClickSelection");
                                                        }
                                                    }}
                                                >
                                                    {entry.ourSource.props.value < 1 ? (
                                                        <>
                                                            <i className=" fa fa-arrow-left mr-3 text-lg"></i>
                                                            <span>Select a source to the left</span>
                                                        </>
                                                    ) : (
                                                        <div className="py-0.5 text-clip overflow-hidden">
                                                            <i className="fa fa-hands-holding-dollar mr-3" />
                                                            <span>Manual Match</span>
                                                        </div>
                                                    )}{" "}
                                                </ButtonNeoGen>
                                                {entry.ourSource.props.value < 1 ? null : (
                                                    <ButtonNeoGen
                                                        mt-1
                                                        className="pl-3 "
                                                        type="primary"
                                                        onClick={() => {
                                                            const sugs = processFlowFieldsQuery.data?.filter(
                                                                (x) =>
                                                                    x.title !== "" &&
                                                                    x.group === 7 &&
                                                                    // make sure it's not a uuid
                                                                    /[a-f0-9]{8}-?[a-f0-9]{4}-?4[a-f0-9]{3}-?[89ab][a-f0-9]{3}-?[a-f0-9]{12}/.test(
                                                                        x.title,
                                                                    ),
                                                            );

                                                            console.error({ entry });
                                                            setAutoMatchTo(entry);
                                                            if (typeof fo.onClickSelection !== undefined) {
                                                                setSearcherClick(() => (selected: any) => {
                                                                    console.error({ selected, id: e2[1], entry });
                                                                    fo.onClickSelection?.({
                                                                        selected,
                                                                        id: e2[1] as number,
                                                                        entry,
                                                                    });
                                                                    alert("clicked2");
                                                                    return true;
                                                                });
                                                            } else {
                                                                alert("No onClickSelection");
                                                            }
                                                            setAutoMatchSuggestions(sugs ?? []);
                                                            setShowAutoMatchModal(true);
                                                            console.log(sugs);
                                                        }}
                                                    >
                                                        <i className="fa fa-robot mr-3"></i>
                                                        Auto Match
                                                    </ButtonNeoGen>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                );
                                break;
                            case "Date":
                                entry[e2[0]] = new Date(e2[1] as string).toLocaleDateString();
                                break;
                            case "Time":
                                entry[e2[0]] = new Date(e2[1] as string).toLocaleTimeString();
                                break;
                            case "Datetime":
                            case "DateTime":
                                if (!e2[1]) {
                                    // e[e2[0]] = "-";
                                } else {
                                    entry[e2[0]] = new Date(e2[1] as string).toLocaleString();
                                }
                                break;
                            case "Phone":
                                entry[e2[0]] = formatPhonenumber(e2[1] as string);
                                break;
                            case "PhoneNoSpace":
                                entry[e2[0]] = formatPhonenumber(e2[1] as string)?.replaceAll(" ", "");
                                break;
                            case "Select":
                                entry[e2[0]] = (
                                    <SelectNeoGen
                                        onChange={(id, value) => {
                                            if (fo.onChange) {
                                                fo.onChange(entry.id, id);
                                            }
                                        }}
                                        value={e2[1] as string | number | undefined}
                                        options={fo.options ?? undefined}
                                    />
                                );
                                break;
                            case "SelectSearch":
                                entry[e2[0]] = (
                                    <SelectNeoGenSearchable
                                        onChange={(id, value) => {
                                            if (fo.onChange) {
                                                fo.onChange(entry.id, id);
                                            }
                                        }}
                                        value={e2[1] as string | number | undefined}
                                        options={fo.options}
                                    />
                                );
                                break;
                            case "Boolean":
                                entry[e2[0]] = e2[1] ? (
                                    <span className="fas fa-circle-check text-green-500" />
                                ) : (
                                    <span className="fas fa-circle-xmark text-red-500" />
                                );
                                break;
                            case "Color":
                                entry[e2[0]] = (
                                    <div
                                        className={"m-0 p-0 border-1 border-green-500 h-8 w-8 bg-" + e2[1] + "-500"}
                                    ></div>
                                );
                                break;
                            case "DisplayItem":
                                // eslint-disable-next-line no-case-declarations
                                const obj: any = e2[1] as object;
                                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                entry[e2[0]] = obj[fo.itemToDisplay!];
                                break;
                            case "FileSize":
                                entry[e2[0]] = bytesToSize(Number(e2[1]));
                                break;
                            case "User":
                                entry[e2[0]] = usersQuery.data?.find((i) => i.id === e2[1])?.name;
                                break;
                            case "Email":
                                entry[e2[0]] = entry[e2[0]] = formatEmail(e2[1] as string);
                                break;
                            case "Company":
                                entry[e2[0]] = companyQuery.data?.find((i) => i.id === e2[1])?.name ?? "-";
                                break;
                            case "RuleType":
                                entry[e2[0]] = ruleTypeQuery.data?.find((i) => i.id === e2[1])?.name ?? "-";
                                break;
                            case "MessageType":
                                entry[e2[0]] = messageTypesQuery.data?.find((i) => i.id === e2[1])?.name ?? "-";
                                break;
                            case "ClientStatus":
                                entry[e2[0]] = statusQuery.data?.find((i) => i.id === e2[1])?.name ?? "-";
                                break;
                            case "ProcessFlowGroup":
                                entry[e2[0]] = groupQuery.data?.find((i) => i.id === e2[1])?.name ?? "-";
                                break;
                            case "RoleGroup":
                                entry[e2[0]] = roleGroupQuery.data?.find((i) => i.id === e2[1])?.name ?? "-";
                                break;
                            case "DefaultSource":
                                entry[e2[0]] = defaultSourcesQuery.data?.find((i) => i.id === e2[1])?.name ?? "-";
                                break;
                            case "Processflow":
                                entry[e2[0]] = processflowQuery.data?.find((i) => i.id === e2[1])?.title ?? "-";
                                break;
                            case "HTML":
                                entry[e2[0]] = <PrintPre>{e2[1]}</PrintPre>;
                                break;
                            case "CustomFunction":
                                entry[e2[0]] = fo.customFunction?.(e2[1], entry);
                        }
                    }
                });
            }

            return entry;
        });
        setEntries(newEntries);
        if (props.headers) {
            setHeaders(props.headers);
        } else {
            if (newEntries[0]) {
                // console.error(newEntries);
                let keys = Object.keys(newEntries[0]);
                keys = keys.map((k) => {
                    return camel2title(k);
                });
                setHeaders(keys);
            }
        }
    }, [
        props.entries,
        props.formatters,
        props.headers,
        props.ignoreFields,
        usersQuery.data,
        groupQuery.data,
        companyQuery.data,
        ruleTypeQuery.data,
        processflowQuery.data,
        roleGroupQuery.data,
        defaultSourcesQuery.data,
        messageTypesQuery.data,
        statusQuery.data,
        openFindField,
        processFlowFieldsQuery.data,
    ]);

    if (!props.entries || props.entries.length === 0) {
        return <></>;
    }

    function formatTableCell(key: string, value: unknown) {
        const typeOfField = props.formatters?.find((formatter) => formatter.field === key)?.type;
        if (typeOfField === "iconShort") {
            return (
                <>
                    <span className={"fal " + value + " mr-3"}></span>({value})
                </>
            );
        } else if (
            typeOfField === "JSON" ||
            (typeof value === "object" &&
                value !== null &&
                typeOfField !== "Boolean" &&
                typeOfField !== "HTML" &&
                typeOfField !== "Select" &&
                typeOfField !== "Searcher" &&
                typeOfField !== "Color" &&
                typeOfField !== "SelectSearch")
        ) {
            return (
                <JsonViewer
                    value={value as object}
                    quotesOnKeys={false}
                    theme={getDarkMode() ? solarized : summerfruitDark}
                    displayDataTypes={false}
                    displayObjectSize={false}
                />
            );
        }
        return value as string;
    }
    function onClickSelection(data: any): void {
        searcherClick({ data, props, entryBeingEdited });
    }

    return (
        <>
            <div
                className={
                    props.actions && props.actionsAreDropDown && !props.hideMargin
                        ? " pb-56 md:overflow-hidden rounded-xl "
                        : ""
                }
            >
                <div
                    className={
                        "  rounded-t-xl  flex flex-col w-full shadow " +
                        " min-w-full  align-middle md:px-6 lg:px-0 dark:border-none dark:rounded-xl" +
                        " dark:border-slate-600/60 ring-1 ring-black ring-opacity-5 " +
                        (props.compact ? " rounded " : " rounded-xl ")
                    }
                >
                    <table
                        className={
                            "table-fixed border-collapse min-w-full divide-y divide-gray-300 dark:divide-gray-800 rounded-xl  "
                        }
                    >
                        <thead
                            className={
                                "overflow-hidden" +
                                (props.compact ? " rounded  " : " rounded-xl  ") +
                                " dark:border-0  " +
                                (props.headerBg ?? " bg-gray-50  dark:bg-slate-800 ") +
                                " transition-colors duration-500 " +
                                (props.refreshing && "bg-red-50 dark:bg-gray-800 ")
                            }
                        >
                            <tr className=" rounded-xl ">
                                {headers.map((h, i) => {
                                    return (
                                        <Fragment key={i}>
                                            {h === "Id" ? (
                                                <></>
                                            ) : (
                                                <th
                                                    key={i}
                                                    scope="col"
                                                    className={
                                                        " " +
                                                        "dark:text-gray-400 tracking-wider  " +
                                                        (props.compact
                                                            ? " text-xs font-light "
                                                            : " text-sm font-semibold  ") +
                                                        (i === 0
                                                            ? "py-" +
                                                              py +
                                                              " pl-4 pr-" +
                                                              px +
                                                              " text-left  text-gray-900 sm:pl-6"
                                                            : "px-" + px + " py-" + py + " text-left  text-gray-900")
                                                    }
                                                >
                                                    {h}
                                                </th>
                                            )}
                                        </Fragment>
                                    );
                                })}
                                {props.actions && !props.actionsAreDropDown && (
                                    <>
                                        {props.actions.map((a: any, idx: number) => (
                                            <th key={idx + JSON.stringify(a)}></th>
                                        ))}
                                    </>
                                )}
                                {props.actions && props.actionsAreDropDown && (
                                    <th className=" dark:text-gray-400 tracking-wider   text-sm font-semibold  px-3 py-3.5 text-left  text-gray-900">
                                        Options
                                    </th>
                                )}
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white  dark:bg-slate-700 dark:divide-gray-900 rounded-xl">
                            {entries.map((entry, idx) => (
                                <tr key={idx} className="">
                                    {Object.entries(entry).map(([key, value], idx2) => {
                                        return (
                                            <Fragment key={key}>
                                                {key === "id" || key === "Stuff" ? (
                                                    <></>
                                                ) : (
                                                    <React.Fragment key={key}>
                                                        <td
                                                            key={key}
                                                            className={
                                                                "" +
                                                                "dark:text-gray-400 " +
                                                                (props.compact
                                                                    ? " text-xs font-light "
                                                                    : " text-sm font-medium ") +
                                                                (props.breaks?.find((x) => x.field === key)?.type ??
                                                                    " break-all ") +
                                                                " " +
                                                                (props.customColumnClasses?.find((x) => x.field === key)
                                                                    ?.classNames ?? "  ") +
                                                                " " +
                                                                (idx2 === 0
                                                                    ? "py-" + pytd + " pl-4 pr-3  text-gray-500 sm:pl-6"
                                                                    : "px-" + px + " py-" + pytd + "  text-gray-500")
                                                            }
                                                        >
                                                            {formatTableCell(key, value)}
                                                        </td>
                                                    </React.Fragment>
                                                )}
                                            </Fragment>
                                        );
                                    })}
                                    {props.actions && props.actionsAreDropDown && (
                                        <td
                                            key={idx}
                                            className={
                                                "text-right dark:text-gray-400 dark:bg-slate-700 w-4 " +
                                                "px-" +
                                                px +
                                                " py-" +
                                                pytd +
                                                " text-sm text-gray-500 dark:bg-gray-800"
                                            }
                                        >
                                            <OptionsDropDown
                                                options={props.actions.map((action) => {
                                                    if (!action.icon) {
                                                        action.icon = "";
                                                    }
                                                    return {
                                                        ...action,
                                                        action: () => {
                                                            action.onClick(entry.id);
                                                        },
                                                        disabled: action.disabled?.(entry.id) ?? false,
                                                    };
                                                })}
                                            />
                                        </td>
                                    )}
                                    {props.actions && !props.actionsAreDropDown && (
                                        <>
                                            {props.actions.map((a: TableAction, idx: number) => (
                                                <td
                                                    key={idx}
                                                    className={
                                                        "text-right dark:text-gray-300 dark:bg-slate-700 " +
                                                        "px-" +
                                                        px +
                                                        " py-" +
                                                        pytd +
                                                        " text-sm text-gray-500 dark:bg-gray-800"
                                                    }
                                                >
                                                    <ButtonNeoGen
                                                        type={a.type}
                                                        className={
                                                            (a.disabled?.(entry.id) ?? false
                                                                ? "bg-gray-400"
                                                                : a.className ??
                                                                  "bg-indigo-600 text-white dark:bg-indigo-800 dark:text-gray-300") +
                                                            " hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2" +
                                                            " focus:ring-indigo-500"
                                                        }
                                                        onClick={() => a.onClick(entry.id)}
                                                        disabled={a.disabled ? a.disabled(entry.id) : false}
                                                        size={a.size || props.compact ? "xxs" : "sm"}
                                                    >
                                                        <span className="whitespace-nowrap">
                                                            {a.icon && (
                                                                <span className={a.icon + " mr-2 whitespace-nowrap"} />
                                                            )}
                                                            {a.label}
                                                        </span>
                                                    </ButtonNeoGen>
                                                </td>
                                            ))}
                                        </>
                                    )}
                                    {props.actions && props.actionsAreDropDown && (
                                        <td
                                            key={idx}
                                            className={
                                                "text-right dark:text-gray-400 dark:bg-slate-700 w-4 " +
                                                "px-" +
                                                px +
                                                " py-" +
                                                pytd +
                                                " text-sm text-gray-500 dark:bg-gray-800"
                                            }
                                        >
                                            <OptionsDropDown
                                                options={props.actions.map((action) => {
                                                    if (!action.icon) {
                                                        action.icon = "";
                                                    }
                                                    return {
                                                        ...action,
                                                        action: () => {
                                                            action.onClick(entry.id);
                                                        },
                                                        disabled: action.disabled?.(entry.id) ?? false,
                                                    };
                                                })}
                                            />
                                        </td>
                                    )}
                                    {props.actions && !props.actionsAreDropDown && (
                                        <>
                                            {props.actions.map((a: TableAction, idx: number) => (
                                                <td
                                                    key={idx}
                                                    className={
                                                        "text-right dark:text-gray-300 dark:bg-slate-700 " +
                                                        "px-" +
                                                        px +
                                                        " py-" +
                                                        pytd +
                                                        " text-sm text-gray-500 dark:bg-gray-800"
                                                    }
                                                >
                                                    <ButtonNeoGen
                                                        type={a.type}
                                                        className={
                                                            a.disabled?.(entry.id) ?? false
                                                                ? "bg-gray-400"
                                                                : a.className ??
                                                                  (a.type
                                                                      ? ""
                                                                      : "bg-indigo-600 text-white dark:bg-indigo-800 dark:text-gray-300") +
                                                                      " hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2" +
                                                                      " focus:ring-indigo-500"
                                                        }
                                                        onClick={() => a.onClick(entry.id)}
                                                        disabled={a.disabled ? a.disabled(entry.id) : false}
                                                        size={a.size || props.compact ? "xxs" : "sm"}
                                                    >
                                                        <span className="whitespace-nowrap">
                                                            {a.icon && (
                                                                <span className={a.icon + " mr-2 whitespace-nowrap"} />
                                                            )}
                                                            {a.label}
                                                        </span>
                                                    </ButtonNeoGen>
                                                </td>
                                            ))}
                                        </>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                        <tfoot className="bg-white dark:bg-slate-700 first-letter:rounded-b-xl overflow-hidden">
                            <tr className="rounded-b-xl overflow-hidden">
                                <td colSpan={1000} className=" rounded-b-xl overflow-hidden">
                                    <div className=" dark:bg-slate-700 rounded-xl border-none border-t-2 border-to divide-none">
                                        <div className="px-4 py-3 sm:px-6 border-xl"></div>
                                    </div>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
            <AutoMatchModal
                suggestions={autoMatchSuggestions}
                toMatch={autoMatchTo}
                isOpen={showAutoMatchModal}
                onClose={() => setShowAutoMatchModal(false)}
                onClick={onClickSelection}
            />
            <FindField
                open={openFindField}
                setOpen={setOpenField}
                onClickSelection={onClickSelection}
                options={options}
            />
            <FindField
                open={openFindField}
                setOpen={setOpenField}
                onClickSelection={onClickSelection}
                options={options}
            />
            <FindField
                open={openFindField}
                setOpen={setOpenField}
                onClickSelection={onClickSelection}
                options={options}
            />
            <FindField
                open={openFindField}
                setOpen={setOpenField}
                onClickSelection={onClickSelection}
                options={options}
            />
        </>
    );
}

export type TableAction = {
    label: string;
    onClick: (id: any) => void;
    className?: string;
    icon?: string;
    size?: "xxs" | "xs" | "sm" | "md" | "lg" | "xl";
    type?: ButtonNeoGenTypes;
    disabled?: (id: any) => boolean;
};

export type Formatter = {
    field: string;
    type:
        | "Searcher"
        | "Date"
        | "Time"
        | "Datetime"
        | "DateTime"
        | "Phone"
        | "PhoneNoSpace"
        | "Select"
        | "SelectSearch"
        | "Boolean"
        | "Color"
        | "DisplayItem"
        | "FileSize"
        | "User"
        | "Company"
        | "RuleType"
        | "MessageType"
        | "ClientStatus"
        | "ProcessFlowGroup"
        | "RoleGroup"
        | "DefaultSource"
        | "Processflow"
        | "HTML"
        | "CustomFunction"
        | "Mimetype"
        | "JSON"
        | "iconShort"
        | "Email";
    itemToDisplay?: string;
    expanded?: boolean;
    options?: Entry[];
    onChange?: (id: any, value: any) => void;
    onClick?: (id: any, value: any) => void;
    onClickSelection?: (data: { entry: { id: number }; selected: { data: any }; id: number }) => void;
    customFunction?: (item: any, entry: any) => any;
    customOptions?: (item: any, entry: any, fo: any) => any;
    switcher?: any[];
};

export type ColumnClass = {
    field: string;
    classNames: string;
};

export type TableNeogenProps = {
    headerBg?: string;
    entries: any[];
    headers?: string[];
    ignoreFields?: string[];
    formatters?: Formatter[];
    breaks?: any[];
    customColumnClasses?: ColumnClass[];
    actions?: TableAction[];
    actionsAreDropDown?: boolean;
    refreshing?: boolean;
    compact?: boolean;
    expandJSON?: boolean;
    hideMargin?: boolean;
};
