import _ from "lodash";
import { useCallback } from "react";
import processflowProgressService from "../../../services/processflow-progress.service";
import processflowStageService from "../../../services/processflow-stage.service";
import processflowService from "../../../services/processflow.service";

export const useGetMap = () => {
    const getMap = useCallback(async (userId: string, processFlowGroup: string) => {
        // First collect together all the stages and steps including the rules for this group
        const stages = _.cloneDeep(
            await processflowStageService.getAllByGroupIdIncludingRuleGroups(Number(processFlowGroup)),
        );
        const entries = _.cloneDeep(
            await processflowService.getAllByGroupIdWithRuleGroupsAndRules(Number(processFlowGroup)),
        );
        const responses = _.cloneDeep(await processflowProgressService.getProgress(userId, Number(processFlowGroup)));
        console.log({ stages, entries, responses });

        // Null guards
        if (!stages || !stages.data || !entries || !responses) {
            return null;
        }
        console.error({ stages: stages.data, entries, responses });

        const clonedStages = _.cloneDeep(stages.data);

        // First sort the stages by order
        clonedStages.sort((a, b) => a.order - b.order);

        // // TODO: come back and give it it's type
        // const map:any = {

        // };
        for (const stage of clonedStages) {
            stage.entries = entries.data
                .filter((entry: any) => entry.stage === stage.id)
                .sort((a: any, b: any) => a.order - b.order);
        }

        return clonedStages;
    }, []);
    return getMap;
};
