import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import PrintPre from "../../../layout/print-pre";
import SelectNeoGen from "../../../layout/select-neogen";
import processflowGroupService from "../../../services/processflow-group.service";
import processflowService from "../../../services/processflow.service";
import { ProcessFlow } from "../../../typings/api/processflow";
import Loader2 from "../../utilities/Loader2";

export type FieldSelectorProps = {
    label: string;
    name: string;
    group: string;
    setGroup: (value: string) => void;
    processflow: string;
    setProcessflow: (value: string) => void;
    // usernameField:string,
    // setUsernameField: (value: string) => void,
    field: string;
    setField: (value: string) => void;
};

export default function FieldSelector(props: FieldSelectorProps) {
    const [groupId, setGroupId] = useState(-1);
    const [processflow, setProcessflow] = useState<ProcessFlow>();
    const [field, setField] = useState("");
    const [fields, setFields] = useState([]);

    // get all the processflow groups including fields from the API
    const processflowGroupQuery = useQuery(["processflowGroups"], async () => {
        const response = await processflowGroupService.getAll();
        if (response) {
            return response.data;
        }
        return [];
    });

    // get all the processflow groups including fields from the API
    const processflowByGroupQuery = useQuery(
        ["processflow", groupId.toString()],
        async () => {
            const response = await processflowService.getAllByGroupIdWithRuleGroupsAndRules(groupId);
            if (response) {
                return response.data;
            }
            return [];
        },
        { enabled: groupId > 0 },
    );

    useEffect(() => {
        if (processflow?.entries) {
            console.log(processflow?.entries?.length);
            try {
                const entries = JSON.parse(processflow?.entries)?.map((entry: any) => {
                    console.log(entry);
                    return {
                        id: entry.id,
                        name: entry.field,
                    };
                });
                setFields(entries);
                console.log(entries);
            } catch (error) {
                console.log(error, processflow?.entries);
            }
        }
    }, [processflow?.entries]);

    return (
        <>
            <p className={"text-gray-900 font-bold text-sm mt-2"}>{props.label}</p>
            <div className="grid grid-cols-3 gap-5">
                {processflowGroupQuery.isLoading ? (
                    <Loader2 />
                ) : (
                    <SelectNeoGen
                        options={processflowGroupQuery.data?.map((group: any) => ({ id: group.id, name: group.name }))}
                        label="Processflow Group"
                        value={groupId}
                        onChange={(e: any) => setGroupId(Number(e))}
                        className="mb-5"
                    />
                )}
                {groupId > 0 && processflowByGroupQuery.isLoading ? (
                    <Loader2 />
                ) : (
                    <SelectNeoGen
                        options={processflowByGroupQuery.data?.map((processflow: any) => ({
                            id: processflow.id,
                            name: processflow.title,
                        }))}
                        label="Processflow"
                        value={processflow?.id}
                        onChange={(e: any) =>
                            setProcessflow(
                                processflowByGroupQuery.data?.find(
                                    (processflow: ProcessFlow) => processflow.id === Number(e),
                                ),
                            )
                        }
                        className="mb-5"
                    />
                )}
                {processflowGroupQuery.isLoading ? (
                    <Loader2 />
                ) : (
                    <SelectNeoGen
                        options={fields}
                        label="Field"
                        value={field}
                        onChange={(e: any) => {
                            props.setField(e);
                            setField(e);
                        }}
                        className="mb-5"
                    />
                )}
            </div>
        </>
    );
}
