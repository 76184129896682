import { useQuery } from "@tanstack/react-query";
import ModalDialog from "../../../../layout/modal-dialog";
import PrintPre from "../../../../layout/print-pre";
import { Suggestion } from "../../../../typings/api/suggestions";
import { Md5 } from "../../../utilities/md5";
import axios from "axios";
import Loader3 from "../../../utilities/Loader3";
import { Search, Option, Detail, Theme } from "searchpal";
import { useEffect, useState } from "react";
import React from "react";
import CardNeoGen from "../../../../layout/card-neogen";
import ButtonNeoGen from "../../../../layout/button-neogen";
import { ProcessFlow } from "../../../../typings/api/processflow";
import processflowStageService from "../../../../services/processflow-stage.service";
import AddProcessflowEntry from "./add-processflow-entry";

type AutoMatchModalProps = {
    isOpen: boolean;
    onClose: () => void;
    suggestions: ProcessFlow[];
    toMatch?: any;
    onClick: (suggestion: { score: number; sentence: string }) => void;
};

const flatten = (obj: Record<string, unknown>, parent?: string): Record<string, unknown> => {
    let res: Record<string, unknown> = {};

    for (const [key, value] of Object.entries(obj)) {
        const propName = parent ? parent + "_" + key : key;
        const flattened: Record<string, unknown> = {};

        if (value instanceof Date) {
            flattened[key] = value.toISOString();
        } else if (typeof value === "object" && value !== null) {
            res = { ...res, ...flatten(value as Record<string, unknown>, propName) };
        } else {
            res[propName] = value;
        }
    }

    return res;
};

export default function AutoMatchModal(props: AutoMatchModalProps) {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showAddNewFieldModal, setShowAddNewFieldModal] = useState(false);
    const [sentences, setSentences] = useState<string[]>([]);

    useEffect(() => {
        const names: string[] = [];
        props.suggestions.map((suggestion) => {
            const parsed = JSON.parse(suggestion.entries ?? "{}");
            console.log({ suggestion, parsed });
            for (const [key, value] of Object.entries(parsed)) {
                const fieldString = (value as any).field as string;
                if (
                    (fieldString.length === 36 && fieldString.substring(8, 1) === "-") ||
                    fieldString.trim().length === 0
                ) {
                    continue;
                } else {
                    names.push((value as any)["field"] as string);
                }
            }
        });
        setSentences([...new Set(names)]);
    }, [props.suggestions]);

    const resultsQuery = useQuery(
        ["suggestions", "props.suggestions", props.toMatch?.fieldTitle],
        async () => {
            try {
                if (props.suggestions.length === 0) {
                    return [];
                }
                if (sentences.length === 0) {
                    return [];
                }
                setLoading(true);
                // console.error({ suggestions: props.suggestions, "sentences", toMatch: props.toMatch });
                const response = await axios.post("https://sentsim.clearerc.com/compare-sentences", {
                    sentence: props.toMatch?.fieldTitle,
                    sentences,
                });
                if (response) {
                    setOpen(true);
                    setLoading(false);
                    return response.data;
                }
            } catch (error) {
                console.error({ error });
            }
            setLoading(false);
            return [];
        },
        { enabled: props.isOpen, cacheTime: 10000, staleTime: 10000 },
    );
    if (props.isOpen) {
        return (
            <>
                <ModalDialog
                    show={props.isOpen}
                    close={props.onClose}
                    title={"Auto Match " + props.toMatch?.fieldTitle}
                    size="xl"
                >
                    {resultsQuery.isLoading ? (
                        <div className="bg-gradient-to-br to-white from-slate-100 dark:from-slate-900 dark:to-gray-800 border-2 border-white rounded-xl dark:bg-gray-800 dark:border-gray-700 dark:shadow-inner shadow">
                            <Loader3 />
                        </div>
                    ) : (
                        <div className="grid grid-cols-5 gap-5 text-center ">
                            <div className="h-28 py-4  overflow-auto bg-gradient-to-br to-white from-slate-100 dark:from-slate-900 dark:to-gray-800 border-4 border-white rounded-xl dark:bg-gray-800 dark:border-gray-700 dark:shadow-inner shadow">
                                <div className=" text-gray-600 dark:text-gray-300 font-bold">Add New Entry</div>
                                <ButtonNeoGen
                                    type="success"
                                    className="mt-2"
                                    onClick={() => {
                                        // alert("Add New");
                                        setShowAddNewFieldModal(true);
                                    }}
                                >
                                    <i className="fas fa-plus mr-3"></i>
                                    Add New
                                </ButtonNeoGen>
                            </div>
                            {loading ? (
                                <div className="bg-gradient-to-br to-white from-slate-100 dark:from-slate-900 dark:to-gray-800 border-2 border-white rounded-xl dark:bg-gray-800 dark:border-gray-700 dark:shadow-inner shadow">
                                    <Loader3 />
                                </div>
                            ) : (
                                <>
                                    {resultsQuery.data?.map(
                                        (
                                            i: {
                                                sentence: string;
                                                score: number;
                                            },
                                            xy: number,
                                        ) => {
                                            return (
                                                <React.Fragment key={xy + i.sentence + i.sentence}>
                                                    {/* <PrintPre>{i.sentence}</PrintPre> */}
                                                    <div className="flex gap-5">
                                                        <div className="w-full min-h-32 my-0">
                                                            <div className="bg-gradient-to-br px-2 to-white from-slate-200 dark:from-slate-900 dark:to-gray-800 border-2 border-white rounded-xl dark:bg-gray-800 dark:border-gray-700 dark:shadow-inner shadow py-6 -q1`mb-0.5">
                                                                <div className=" text-gray-700 dark:text-gray-300">
                                                                    {i.sentence}
                                                                </div>

                                                                <ButtonNeoGen
                                                                    type="info"
                                                                    className="my-1"
                                                                    size="xs"
                                                                    onClick={() => {
                                                                        console.error(i);
                                                                        alert("clicked");
                                                                        props.onClick(i);
                                                                    }}
                                                                >
                                                                    Auto Match
                                                                </ButtonNeoGen>
                                                                <div className="mt-0  text-gray-700 dark:text-gray-300 text-xs">
                                                                    Score:
                                                                    <span className="text-green-700 ml-3">
                                                                        {Math.round(i.score * 1000) / 10}% match
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            );
                                        },
                                    )}
                                </>
                            )}
                        </div>
                    )}
                </ModalDialog>
                {showAddNewFieldModal && (
                    <AddProcessflowEntry
                        show={showAddNewFieldModal}
                        close={(closeParent = true) => {
                            setShowAddNewFieldModal(false);
                            if (closeParent) {
                                props.onClose();
                            }
                        }}
                        details={props.toMatch}
                    />
                )}
            </>
        );
    } else {
        return null;
    }
}
