import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import PageDescription from "../../../layout/page-description";
import externalSyncService, { Deal } from "../../../services/external-sync.service";
import PrintPre from "../../../layout/print-pre";
import Loader3 from "../../utilities/Loader3";
import { Key, useCallback, useState } from "react";
import { formatPhonenumber } from "../../utilities/formatters";
import TableNeogen from "../../../layout/table-neogen";
import usersService from "../../../services/users.service";
import userService from "../../../services/user.service";
import SelectNeoGen from "../../../layout/select-neogen";
import SelectNeoGenSearchable from "../../../layout/select-neogen-searchable";
import ButtonNeoGen from "../../../layout/button-neogen";
import { debounce } from "lodash";
import { de } from "date-fns/locale";
import { usePaginatedResultItems } from "../../../hooks/usePaginatedResults";
import { PaginatedView } from "../../../layout/PaginatedView";
import UserCompanyPicker from "../../../layout/user-company-picker";

const ITEMS_PER_PAGE = 25;

type ModalType = "create" | "edit" | "none";
type QueryKey = ["deals", string];

export default function ManualSync() {
    const [affiliate, setAffiliate] = useState<string | undefined>("");
    const [debouncedSearch, setDebouncedSearch] = useState("");
    const [userPickerOpen, setUserPickerOpen] = useState(false);

    const updateDebouncedSearch = useCallback(() => {
        // Debounce the affiliate search
        debounce(() => {
            setDebouncedSearch(affiliate ?? "");
        }, 500)();
    }, [affiliate]);

    const fetchDeals = async ({
        pageParam,
        queryKey,
    }: {
        pageParam?: { hasNextPage: boolean; startAt?: number };
        queryKey: QueryKey;
    }) => {
        const [_query, searchQuery] = queryKey;
        const { hasNextPage, startAt = 0 } = pageParam ?? { hasNextPage: true, startAt: 0 };
        try {
            if (!hasNextPage) {
                return { pages: [], total: 0, hasNextPage: false };
            }

            const response = await externalSyncService.getDealsPaginated(startAt, ITEMS_PER_PAGE, searchQuery);

            if (!response) {
                console.error("Could not fetch monday data");
                return { pages: [], total: 0, hasNextPage: false };
            }
            console.error({ response });
            const { total, pageItems } = response;
            console.error({ total, pageItems });
            return {
                pages: pageItems || [],
                hasNextPage,
                startAt: startAt + (pageItems.length || 0),
                total,
            };
        } catch (error) {
            console.error("Could not fetch deals");
            console.error(error);
            throw error;
        }
    };

    const { data, isFetchingNextPage, fetchNextPage, refetch } = useInfiniteQuery(
        ["deals", debouncedSearch], // Add the searchQuery parameter to the query key
        fetchDeals,
        {
            refetchOnMount: false,
            keepPreviousData: false,
            refetchOnWindowFocus: false,
            getNextPageParam: (lastPage) => {
                const hasNextPage = lastPage?.hasNextPage || null;
                return { hasNextPage, startAt: lastPage?.startAt };
            },
        },
    );

    // const externalSyncServiceQuery = useQuery(["deals"], async () => {
    //     const result = await externalSyncService.getDeals();
    //     if (result) {
    //         return result;
    //     }
    //     return [];
    // });

    const usersQuery = useQuery(["users"], async () => {
        const result = await userService.getAll();
        if (result) {
            return result.data;
        }
        return [];
    });
    const affiliatesQuery = useQuery(["users", "affiliates", "deal"], async () => {
        const response = await usersService.getAll({ filters: { role: "Affiliate" } });
        return response?.data || [];
    });

    const dealtems = usePaginatedResultItems<
        {
            pages: Deal[];
            hasNextPage: boolean;
        },
        Deal
    >(data, (response) => response.pages);
    const processeddeals = dealtems.map((deal) => ({
        ...deal,
        // createdBy: [deal.createdByUser?.firstName, deal.createdByUser?.lastName].join(" "),
        // createdFor: [deal.data.firstName, deal.data.lastName].join(" "),
    }));

    const deals = (processeddeals || []) as Deal[];

    return (
        <>
            <PageDescription
                title="Manual Monday Sync"
                description="This is useful if you have made changes to monday and they don't seem to be showing up after a couple of minutes.  This page will also allow you to see the differences between monday and the app.  You can also use this page to manually sync monday data. Bear in mind that this information should be synced automatically."
            >
                <div className="text-left">
                    <UserCompanyPicker
                        open={userPickerOpen}
                        setOpen={(val) => setUserPickerOpen(val)}
                        setUser={(user) => {
                            setUserPickerOpen(false);
                            setAffiliate(user.id);
                        }}
                        showCompanies={false}
                        userList={affiliatesQuery.data || []}
                    />
                    <ButtonNeoGen
                        className="ml-2 mb-2"
                        onClick={() => {
                            setUserPickerOpen(true);
                        }}
                        text="Filter By Affiliate"
                    />
                    {/* Reset Filters Button */}
                    <ButtonNeoGen
                        type="secondary"
                        className="ml-2"
                        onClick={() => {
                            setAffiliate(undefined);
                        }}
                        text="Reset Filters"
                    />
                </div>
            </PageDescription>

            {usersQuery.isLoading || !deals || affiliatesQuery.isLoading ? (
                <>
                    <Loader3 />
                    {/* <PrintPre>{externalSyncServiceQuery.data}</PrintPre> */}
                </>
            ) : (
                // <>
                //     {/* <PrintPre>{data}</PrintPre> */}
                //     <PrintPre>{deals}</PrintPre>
                // </>

                <div className="flex flex-col">
                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        {/* <PrintPre>{affiliatesQuery}</PrintPre> */}
                        {/* <PrintPre>{Object.values(externalSyncServiceQuery.data.boards[0].items)}</PrintPre> */}
                        {/* <PrintPre>{Object.values(externalSyncServiceQuery.data.boards[0].items)}</PrintPre> */}

                        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                            <PaginatedView
                                onRequestToLoadMore={fetchNextPage}
                                isLoading={isFetchingNextPage}
                                currentCount={deals.length}
                                // pages[0] because all pages have the same `total`
                                totalCount={data?.pages[0]?.total || 0}
                            >
                                <TableNeogen
                                    headers={[
                                        "Name",
                                        "",
                                        "Stage",
                                        "Phone",
                                        "Last Contacted",
                                        "Monday Affiliate",
                                        "ClearERC User",
                                    ]}
                                    formatters={[
                                        {
                                            field: "Phone",
                                            type: "Phone",
                                        },
                                        {
                                            field: "LastContacted",
                                            type: "Date",
                                        },
                                        {
                                            field: "Stuff",
                                            type: "HTML",
                                        },
                                        {
                                            field: "Color",
                                            type: "Color",
                                        },
                                        {
                                            field: "AffiliateId",
                                            type: "SelectSearch",
                                            options: affiliatesQuery.data
                                                ?.map((e: any) => {
                                                    return {
                                                        id: e.id,
                                                        name: e.name,
                                                    };
                                                })
                                                ?.sort((a: { name: string }, b: { name: string }) => {
                                                    if (a.name < b.name) {
                                                        return -1;
                                                    }
                                                    if (a.name > b.name) {
                                                        return 1;
                                                    }
                                                    return 0;
                                                }),
                                        },
                                        {
                                            field: "PlatformLink",
                                            type: "SelectSearch",
                                            options: usersQuery.data?.map((e: any) => {
                                                return {
                                                    id: e.id,
                                                    name: e.name,
                                                };
                                            }),
                                        },
                                        {
                                            field: "PlatformLink",
                                            type: "SelectSearch",
                                            options: usersQuery.data
                                                ?.map((e: any) => {
                                                    // console.error(e);
                                                    return {
                                                        id: e.id,
                                                        name: e.name,
                                                    };
                                                })
                                                .sort((a: any, b: any) => {
                                                    if (a.name < b.name) {
                                                        return -1;
                                                    }
                                                    if (a.name > b.name) {
                                                        return 1;
                                                    }
                                                    return 0;
                                                }),
                                        },
                                    ]}
                                    entries={
                                        (deals as any)
                                            ?.filter((e: any) => {
                                                console.log(e);
                                                console.log(
                                                    "Comparing " +
                                                        externalSyncService.getAffiliateFromItem(e) +
                                                        " to " +
                                                        affiliate +
                                                        "",
                                                );
                                                return (
                                                    externalSyncService.getAffiliateFromItem(e) == affiliate ||
                                                    affiliate == ""
                                                );
                                            })
                                            ?.map(
                                                (
                                                    e: { boards: any[] | undefined; data: any[] | undefined },
                                                    idx: Key | null | undefined,
                                                ) => {
                                                    return {
                                                        Name: externalSyncService.getNameFromItem(e) ?? "No Name",
                                                        Color: externalSyncService.getStage(e).color ?? "",
                                                        Stage: externalSyncService.getStage(e).text ?? "No Stage",
                                                        Phone: externalSyncService.getPhone(e) ?? "No Phone",
                                                        LastContacted:
                                                            externalSyncService.getLastContactedFromItem(e) ??
                                                            "No Last Contacted",
                                                        AffiliateId:
                                                            externalSyncService.getAffiliateFromItem(e) ??
                                                            "No Affiliate",
                                                        PlatformLink:
                                                            externalSyncService.getLinkFromItem(e) ?? "No Link",
                                                        Stuff: (e as unknown as any).column_values,
                                                    };
                                                },
                                            )
                                        // .sort((a: { Stage: number }, b: { Stage: number }) => {
                                        //     if (a.Stage < b.Stage) {
                                        //         return -1;
                                        //     }
                                        //     if (a.Stage > b.Stage) {
                                        //         return 1;
                                        //     }
                                        //     return 0;
                                        // })
                                    }
                                />
                            </PaginatedView>
                            {/*
                                            ]}
                                        />

                                {/* ); */}
                            {/* }, */}
                            {/* )} */}
                        </div>
                        {
                            // <PrintPre>{externalSyncServiceQuery.data}</PrintPre>
                        }
                    </div>
                </div>
            )}
        </>
    );
}
