import { Detail, Search, Option } from "searchpal";
import PrintPre from "../print-pre";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import processflowService from "../../services/processflow.service";
import { JsonViewer } from "@textea/json-viewer";
import React from "react";
import { SearcherOptions } from "../table-neogen";

function inIframe() {
    if (window.location.search === "?inIframe=1") {
        return true;
    }
    try {
        document.body.classList.add("iframe");
        return window.self !== window.top;
    } catch (e) {
        document.body.classList.add("inIframe");
        return true;
    }
}
function getDarkMode() {
    const currentLocalStorageTheme = inIframe() ? "light" : localStorage.getItem("theme");
    if (currentLocalStorageTheme !== null) {
        return currentLocalStorageTheme === "dark";
    } else {
        // Couldn't find theme in local storage
        // console.error("Theme not stored");
        return window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches;
    }
}

type FindFieldProps = {
    open: boolean;
    setOpen: (closeParent: boolean) => void;
    onClickSelection: (options: { step: number; field: string }) => void;
    options?: SearcherOptions[];
};

export default function FindField(props: FindFieldProps) {
    const processFlowFieldsQuery = useQuery(["processFlowFields"], async () => {
        const response = await processflowService.getAllByOrderForGroupWithRuleGroupsAndRules(7);
        if (response) {
            return response.data;
        }
        return [];
    });
    if (processFlowFieldsQuery.isLoading) {
        return <>Loading...</>;
    }
    function ucwords(str: string) {
        return (str + "").replace(/^([a-z])|\s+([a-z])/g, function ($1) {
            return $1.toUpperCase();
        });
    }
    return (
        <>
            <Search
                animate="fade"
                algo="combo"
                labels={{ title: "Search for a field..." }}
                open={props.open}
                dark={getDarkMode()}
                onClose={() => props.setOpen(false)}
            >
                {processFlowFieldsQuery.data?.map((step: any, idx: number) => (
                    <React.Fragment key={step.id + "-" + idx + step.title}>
                        {JSON.parse(step.entries ?? "{ }").map((field: any, idx: number) => (
                            <>
                                {!["wysiwyg", "button"].includes(field?.type) && (
                                    <Option
                                        label={ucwords(field?.field.trim())}
                                        sublabel={step.title}
                                        href={`/admin/tools/fix-progress/user/1`}
                                        keywords={[step?.id, step?.title, field.field, field?.type, field.description]}
                                        key={step?.id + "22-" + idx}
                                        onClick={() => {
                                            props.onClickSelection({ step, field });
                                            props.setOpen(false);
                                        }}
                                    >
                                        <Detail
                                            label="Field Details"
                                            value={
                                                <>
                                                    <div>
                                                        <span className="font-medium">Step: </span>
                                                        {step.title}
                                                    </div>
                                                    <div>
                                                        <span className="font-medium">Description: </span>
                                                        {field.description}
                                                    </div>
                                                    <div>
                                                        <span className="font-medium">Required: </span>
                                                        {field?.required ? "Yes" : "No"}
                                                    </div>

                                                    <div>
                                                        <span className="font-medium">Field Type: </span>
                                                        {field?.type}
                                                    </div>
                                                    {field.ruleGroups?.length > 0 && (
                                                        <>
                                                            <div>
                                                                <span className="font-medium">
                                                                    Rule Groups Are Or:{" "}
                                                                </span>
                                                                {field?.ruleGroupsAreOr ? "Yes" : "No"}
                                                            </div>
                                                            <div>
                                                                <span className="font-medium">Rule Groups: </span>
                                                                {field.ruleGroups?.map((ruleGroup: any) => (
                                                                    <>
                                                                        <div>
                                                                            <span className="font-medium mb-5">
                                                                                {/* Rule Group:{" "} */}
                                                                                {ruleGroup.name + " " + ruleGroup.id}
                                                                                {ruleGroup.businessRules?.map(
                                                                                    (rule: any) => (
                                                                                        <>
                                                                                            <div>
                                                                                                <span className="font-medium">
                                                                                                    Rule:{" "}
                                                                                                </span>
                                                                                                <PrintPre>
                                                                                                    {rule}
                                                                                                </PrintPre>
                                                                                            </div>
                                                                                        </>
                                                                                    ),
                                                                                )}
                                                                            </span>
                                                                        </div>
                                                                    </>
                                                                ))}
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                            }
                                        />
                                    </Option>
                                )}{" "}
                            </>
                        ))}
                    </React.Fragment>
                ))}
            </Search>
        </>
    );
}
