import { useMutation, useQuery } from "@tanstack/react-query";
import mondaySyncFieldsService, { MondaySyncFields } from "../../../services/monday-sync-fields.service";
import PrintPre from "../../../layout/print-pre";
import Loader3 from "../../utilities/Loader3";
import TableNeogen from "../../../layout/table-neogen";
import { de } from "date-fns/locale";
import userService from "../../../services/user.service";
import processflowService from "../../../services/processflow.service";
import { useState } from "react";
import AddProcessflowEntry from "./modals/add-processflow-entry";

const processFlowFields = [
    { id: 1, name: "Yes" },
    { id: 0, name: "No" },
    { id: -1, name: "Unknown" },
];
const companyFields = [
    { id: 1, name: "company" },
    { id: 0, name: "No" },
    { id: -1, name: "Unknown" },
];
const userFields = [
    { id: 1, name: "user" },
    { id: 0, name: "No" },
    { id: -1, name: "Unknown" },
];
const yesNos = [
    { id: 1, name: "Yes" },
    { id: 0, name: "No" },
    { id: -1, name: "Unknown" },
];
const sources = [
    { id: -1, name: "-- Please Select --" },
    { id: 1, name: "ProcessFlow" },
    { id: 2, name: "Company" },
    { id: 3, name: "Company Owner" },
    { id: 4, name: "User" },
];

type Field = {
    id: number;
    field: MondaySyncFields;
};

/**
 * A React component that syncs fields between Monday.com and the application.
 * @returns A JSX element that displays the synced fields.
 */
export default function SyncFields() {
    const [showAddNewFieldModal, setShowAddNewFieldModal] = useState(false);
    const mondaySyncFieldsQuery = useQuery(["mondaySyncFields"], async () => {
        const response = await mondaySyncFieldsService.getAll();
        if (response) {
            return response.data;
        }
        return [];
    });
    const oneUserQuery = useQuery(["oneUser"], async () => {
        const response = await userService.getSome(0, 1);
        return response?.data ?? [];
    });
    const processFlowFieldsQuery = useQuery(["processFlowFields"], async () => {
        const response = await processflowService.getAllByOrderForGroupWithRuleGroupsAndRules(7);
        if (response) {
            return response.data;
        }
        return [];
    });

    const ourSourceMutation = useMutation({
        mutationFn: async (data: Field) => {
            console.error({ data });
            const { id, field } = data;
            if (!field.ourFieldName) {
                field.ourFieldName = "";
            }
            return mondaySyncFieldsService.update(id, field);
        },
    });

    const ourFieldMutation = useMutation({
        mutationFn: async (data: Field) => {
            console.error({ data });
            const { id, field } = data;
            if (!field.ourFieldName) {
                field.ourFieldName = "";
            }
            return mondaySyncFieldsService.update(id, field);
        },
    });

    if (mondaySyncFieldsQuery.isLoading || oneUserQuery.isLoading || processFlowFieldsQuery.isLoading) {
        return <Loader3 />;
    }

    return (
        <>
            <div>
                <h1>Sync Fields</h1>

                <TableNeogen
                    entries={(mondaySyncFieldsQuery.data ?? []).map((e: any) => {
                        // return e;
                        return {
                            id: e.id,
                            fieldTitle: e.fieldTitle,
                            ourSource: e.ourSource,
                            ourFieldName: e.ourFieldName,

                            theirFieldName: e.theirFieldName,
                            theirSource: e.theirSource,
                            sendToErc: e.sendToErc,
                            sendToMonday: e.sendToMonday,
                            used: e.used,
                        };
                    })}
                    ignoreFields={["theirFieldName"]}
                    formatters={[
                        {
                            field: "ourFieldName",
                            type: "Searcher",
                            onClickSelection: async (details: { entry: { id: number }; selected: { data: any } }) => {
                                // alert("onClickSelection");
                                const originalField = mondaySyncFieldsQuery.data?.find(
                                    (e: any) => e.id === details.entry.id,
                                    3,
                                );
                                if (originalField) {
                                    console.error({ originalField });
                                    originalField.ourFieldName =
                                        details.selected.data.sentence ??
                                        details.selected.data.id ??
                                        details.selected.data.field.id;
                                    await ourFieldMutation.mutateAsync({ id: details.entry.id, field: originalField });
                                    setShowAddNewFieldModal(false);
                                    alert("Updated");
                                } else {
                                    alert("originalField not found");
                                }
                            },
                            customOptions: (item: any, entry: any, fo: any) => {
                                let response = [];
                                // console.log(item.ourSource);
                                switch (item.ourSource) {
                                    case 2: {
                                        // Company3332
                                        response = companyFields
                                            ?.map((e: any) => {
                                                return {
                                                    id: e.id,
                                                    name: e.name,
                                                };
                                            })
                                            ?.sort((a: { name: string }, b: { name: string }) => {
                                                if (a.name < b.name) {
                                                    return -1;
                                                }
                                                if (a.name > b.name) {
                                                    return 1;
                                                }
                                                return 0;
                                            });
                                        break;
                                    }
                                    case 3: {
                                        // Company Owner
                                        response = userFields
                                            ?.map((e: any) => {
                                                return {
                                                    id: e.id,
                                                    name: e.name,
                                                };
                                            })
                                            ?.sort((a: { name: string }, b: { name: string }) => {
                                                if (a.name < b.name) {
                                                    return -1;
                                                }
                                                if (a.name > b.name) {
                                                    return 1;
                                                }
                                                return 0;
                                            });
                                        break;
                                    }
                                    case 4: {
                                        // User
                                        response = [
                                            {
                                                id: 1,
                                                name: "test",
                                            },
                                        ];
                                        break;
                                    }
                                    case 1:
                                    default: {
                                        // User
                                        return [
                                            {
                                                id: -1,
                                                name: "Please select a source       ",
                                            },
                                        ];
                                    }
                                }
                            },
                        },
                        {
                            field: "ourSource",
                            type: "Select",
                            onChange: (id: any, ourSourceIn: any) => {
                                const data = {
                                    id,
                                    ourSourceIn,
                                };
                                const originalField = mondaySyncFieldsQuery.data?.find((e: any) => e.id === id);
                                const originalSource = originalField?.ourSource ?? "";
                                if (ourSourceIn !== originalSource && originalField) {
                                    originalField.ourSource = ourSourceIn;
                                    console.error({ originalField });

                                    ourSourceMutation.mutateAsync({ id, field: originalField });
                                }
                            },
                            options: sources
                                ?.map((e: any) => {
                                    return {
                                        id: e.id,
                                        name: e.name,
                                    };
                                })
                                ?.sort((a: { name: string }, b: { name: string }) => {
                                    if (a.name < b.name) {
                                        return -1;
                                    }
                                    if (a.name > b.name) {
                                        return 1;
                                    }
                                    return 0;
                                }),
                        },
                        {
                            field: "sendToErc",
                            type: "Select",
                            options: yesNos
                                ?.map((e: any) => {
                                    return {
                                        id: e.id,
                                        name: e.name,
                                    };
                                })
                                ?.sort((a: { name: string }, b: { name: string }) => {
                                    if (a.name < b.name) {
                                        return -1;
                                    }
                                    if (a.name > b.name) {
                                        return 1;
                                    }
                                    return 0;
                                }),
                        },
                        {
                            field: "sendToMonday",
                            type: "Select",
                            options: yesNos
                                ?.map((e: any) => {
                                    return {
                                        id: e.id,
                                        name: e.name,
                                    };
                                })
                                ?.sort((a: { name: string }, b: { name: string }) => {
                                    if (a.name < b.name) {
                                        return -1;
                                    }
                                    if (a.name > b.name) {
                                        return 1;
                                    }
                                    return 0;
                                }),
                        },
                        {
                            field: "used",
                            type: "Select",
                            options: yesNos
                                ?.map((e: any) => {
                                    return {
                                        id: e.id,
                                        name: e.name,
                                    };
                                })
                                ?.sort((a: { name: string }, b: { name: string }) => {
                                    if (a.name < b.name) {
                                        return -1;
                                    }
                                    if (a.name > b.name) {
                                        return 1;
                                    }
                                    return 0;
                                }),
                        },
                    ]}
                />
            </div>
            {showAddNewFieldModal && (
                <AddProcessflowEntry
                    show={showAddNewFieldModal}
                    close={(closeParent = true) => setShowAddNewFieldModal(false)}
                    details={<></>}
                />
            )}
        </>
    );
}
