/* This example requires Tailwind CSS v2.0+ */
//import React, { Fragment, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/solid";
import { useState, useEffect, Fragment } from "react";
import * as React from "react";
import PrintPre from "./print-pre";
import { Label } from "./label";

function classNames(...classes: any) {
    return classes.filter(Boolean).join(" ");
}
import { Combobox } from "@headlessui/react";

type Person = {
    name: string;
    id: string | number;
};

export default function SelectNeoGenSearchable({ options, label, onChange, value }: SelectNeoGenProps) {
    const [query, setQuery] = useState("");
    const [selectedPerson, setSelectedPerson] = useState(options?.find((person) => person.id === value));

    useEffect(() => {
        onChange(selectedPerson?.id ?? "", selectedPerson?.name ?? "");
    }, [onChange, selectedPerson?.id, selectedPerson?.name]);

    // Reset filters if parent changes value
    useEffect(() => {
        // setSelectedPerson(options?.find((person) => person.id === value));
        if (value === undefined || value === null || value === "") {
            setQuery("");
            setSelectedPerson(undefined);
        }
    }, [value]);

    const filteredOptions =
        query === ""
            ? options
            : options?.filter((person) => {
                  return person?.name?.toLowerCase().includes(query.toLowerCase());
              });

    return (
        <Combobox as="div" value={selectedPerson} onChange={setSelectedPerson}>
            <Combobox.Label className="text-left block text-sm leading-6 text-gray-800 dark:text-gray-400">
                {label}
            </Combobox.Label>
            <div className="relative mt-0">
                <Combobox.Input
                    className="w-full rounded-md border-0 bg-white dark:bg-gray-800 dark:text-gray-500 dark:ring-gray-900 -py-1 my-0 h-10 pl-3 pr-10 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={(event) => {
                        setQuery(event.target.value);
                        onChange(event.target.value);
                    }}
                    displayValue={(person: Person) => person?.name ?? ""}
                />
                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400 dark:text-gray-300" aria-hidden="true" />
                </Combobox.Button>

                {(filteredOptions?.length ?? 0) > 0 && (
                    <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white dark:bg-gray-800 py-1 dark:ring-gray-900 white text-base dark:text-gray-300 after:shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {filteredOptions?.map((person) => (
                            <Combobox.Option
                                key={person.id}
                                value={person}
                                className={({ active }) =>
                                    classNames(
                                        "relative cursor-default select-none py-2 pl-3 pr-9",
                                        active
                                            ? "bg-indigo-600 text-white dark:text-gray-200 "
                                            : "text-gray-700 dark:text-gray-400",
                                    )
                                }
                            >
                                {({ active, selected }) => (
                                    <>
                                        <span className={classNames("block ", selected && "font-semibold")}>
                                            {person.name}
                                        </span>

                                        {selected && (
                                            <span
                                                className={classNames(
                                                    "absolute inset-y-0 right-0 flex items-center pr-4",
                                                    active
                                                        ? "text-white dark:text-gray-300"
                                                        : "text-indigo-600 dark:text-indigo-300",
                                                )}
                                            >
                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                            </span>
                                        )}
                                    </>
                                )}
                            </Combobox.Option>
                        ))}
                    </Combobox.Options>
                )}
            </div>
        </Combobox>
    );
}

type Entry = {
    id?: number | string | null;
    name?: string | null;
};

type SelectNeoGenProps = {
    options?: (unknown & Entry)[];
    label?: string;
    value?: number | string;
    onChange: (idx: number | string, text?: string) => void;
    className?: string;
    description?: string;
    ref?: React.Ref<any>;
    required?: boolean;
};
