import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import processflowService from "../../../services/processflow.service";

export default function useStep({ groupId }: { groupId: number }) {
    // alert(groupId);
    const stepsQuery = useQuery(["steps", groupId.toString()], async () => {
        const response = await processflowService.getAllByGroupIdWithRuleGroupsAndRules(Number(groupId));
        if (Number.isNaN(Number(groupId))) {
            console.trace("groupId  is NaN", groupId);
        }
        if (response) {
            console.error(response.data);
            return response.data;
        }
        console.error("no response");
        return [];
    });

    const getStageFromStepId = useCallback(
        (id?: number) => {
            if (!(stepsQuery.isSuccess && id)) {
                return null;
            }
            const step = stepsQuery.data?.find((step) => step.id === id);
            return step?.stage;
        },
        [stepsQuery.data, stepsQuery.isSuccess],
    );

    const getStepFromId = useCallback(
        (id?: number) => {
            console.warn("Getting step from id: ", id, stepsQuery.isSuccess, groupId);
            if (!(stepsQuery.isSuccess && id)) {
                console.error("No steps or id", stepsQuery.isSuccess, id);
                return null;
            }
            const step = stepsQuery.data?.find((step) => step.id === id);
            console.warn({ step, haystack: stepsQuery.data });
            return step;
        },
        [groupId, stepsQuery.data, stepsQuery.isSuccess],
    );
    return { isLoading: stepsQuery.isLoading, getStepFromId };
}
