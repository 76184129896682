import { useQuery } from "@tanstack/react-query";
import PageDescription from "../../../layout/page-description";
import usersService from "../../../services/users.service";
import Loader3 from "../../utilities/Loader3";
import companyService from "../../../services/company.service";
import { useState } from "react";
import ButtonNeoGen from "../../../layout/button-neogen";
import PrintPre from "../../../layout/print-pre";
import { ClearERCUser } from "../../../typings/api/clear-erc-user";
import processflowProgressService from "../../../services/processflow-progress.service";
import { useParams } from "react-router-dom";
import CardNeoGen from "../../../layout/card-neogen";
import SwalNeogenFire from "../../../layout/swal-neogen";
import UserCompanyPicker from "../../../layout/user-company-picker";
import { useNavigate } from "react-router-dom";
import { Company } from "../../../companies/domain/company";

export default function FixProgress({ isUser, isCompany }: { isUser?: boolean; isCompany?: boolean }) {
    const params = useParams();
    const [open, setOpen] = useState(isUser || isCompany ? false : true);
    const navigate = useNavigate();

    // Get all users
    const progressFlowData = useQuery(["progressflow-data"], async () => {
        const response = await processflowProgressService.getProgress(params.userId ?? "", 7);
        if (response) {
            console.error(response.data);
            // Return sorted data (by name)
            return response.data;
        }
        return [];
    });
    const allUsersQuery = useQuery(["users-with-companies"], async () => {
        const response = await usersService.getAll();
        if (response) {
            console.error(response.data);
            // Return sorted data (by name)
            return response.data;
        }
        return [];
    });
    const allCompaniesQuery = useQuery(["companies"], async () => {
        const response = await companyService.getAll();
        if (response) {
            // Return sorted data (by name)
            return response.data;
        }
        return [];
    });

    return (
        <>
            <PageDescription
                title={"Fix " + (isUser ? "User " : "") + (isCompany ? "Company " : "") + "Progress"}
                description="Allows you to compare multiple processflows and fix missing progress."
            />
            {allUsersQuery.isLoading || allCompaniesQuery.isLoading || progressFlowData.isLoading ? (
                <Loader3 />
            ) : (
                <div>
                    {isUser || isCompany ? (
                        <>
                            <ButtonNeoGen onClick={() => setOpen(true)}>Search for something to repair</ButtonNeoGen>
                            <div>
                                <span className="font-bold mr-5">Number of Processflows:</span>
                                <span>{progressFlowData.data?.length}</span>
                                <div className="grid grid-cols-3 gap-5">
                                    {progressFlowData.data?.map((progress) => {
                                        return (
                                            <div className="block" key={progress.id}>
                                                <div className="col-span-1 max-h-72  overflow-scroll-y">
                                                    <CardNeoGen>
                                                        <div className="">
                                                            <span className="font-bold mr-5">ID: </span>
                                                            {progress.id}
                                                        </div>
                                                        <div className="font-bold mr-5">
                                                            <span className="font-bold mr-5">Responses:</span>
                                                            {
                                                                Object.keys(
                                                                    JSON.parse(progress.data ?? "{}").entriesByField,
                                                                )?.length
                                                            }
                                                        </div>
                                                        <ButtonNeoGen
                                                            type="danger"
                                                            className="my-5"
                                                            onClick={() => {
                                                                SwalNeogenFire({
                                                                    title: "Are you sure?",
                                                                    html:
                                                                        "You will not be able to recover this processflow progress with <span className='text-red-500'><b >" +
                                                                        Object.keys(
                                                                            JSON.parse(progress.data ?? "{}")
                                                                                .entriesByField,
                                                                        )?.length +
                                                                        " responses!</span></b>",
                                                                    icon: "warning",
                                                                    showCancelButton: true,
                                                                    confirmButtonText: "Yes, delete it!",
                                                                    cancelButtonText: "No, keep it",
                                                                }).then((result) => {
                                                                    if (result.isConfirmed) {
                                                                        processflowProgressService
                                                                            .deleteByID(progress.id)
                                                                            .then((res) => {
                                                                                SwalNeogenFire({
                                                                                    title: "Deleted!",
                                                                                    text: "Your processflow progress has been deleted.",
                                                                                    icon: "success",
                                                                                    showConfirmButton: false,
                                                                                    timer: 1500,
                                                                                }).then(() => {
                                                                                    window.location.reload();
                                                                                });
                                                                            });
                                                                    } else {
                                                                        SwalNeogenFire({
                                                                            title: "Cancelled",
                                                                            text: "Your processflow progress is safe :)",
                                                                            icon: "error",
                                                                            showConfirmButton: false,
                                                                            timer: 1500,
                                                                        });
                                                                    }
                                                                });
                                                            }}
                                                        >
                                                            <i className="fas fa-trash-alt mr-2"></i> Delete Processflow
                                                            Progress
                                                        </ButtonNeoGen>

                                                        <div className="font-bold mr-5">
                                                            <span className="font-bold mr-5">Details:</span>
                                                            {Object.entries(
                                                                JSON.parse(progress.data ?? "{}").entriesByField,
                                                            ).map((f) => {
                                                                return (
                                                                    <div key={f[0]}>
                                                                        <span className="font-bold mr-5">Field:</span>
                                                                        {f[0]}
                                                                        <br />
                                                                        <span className="font-bold mr-5">Value:</span>
                                                                        <PrintPre>{f[1] ?? ""}</PrintPre>
                                                                        <br />
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </CardNeoGen>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </>
                    ) : (
                        <></>
                    )}

                    <UserCompanyPicker
                        open={open}
                        setOpen={setOpen}
                        setCompany={(company: Company) => {
                            navigate(`/admin/tools/fix-progress/company/${company?.id}`);
                        }}
                        setUser={(user: ClearERCUser) => {
                            navigate(`/admin/tools/fix-progress/user/${user?.id}`);
                            setOpen(false);
                        }}
                        userList={allUsersQuery.data ?? []}
                        companyList={allCompaniesQuery.data ?? []}
                    />
                </div>
            )}
        </>
    );
}
