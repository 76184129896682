import { Link } from "react-router-dom";

function classNames(...classes: any) {
    return classes.filter(Boolean).join(" ");
}

export default function ActionsPanel(props: ActionsPanelProps) {
    if (props.actions.length === 0) {
        return <></>;
    }
    return (
        <section aria-labelledby="quick-links-title">
            <div
                className={classNames(
                    "  divide-y divide-gray-200 ",
                    "sm:divide-y-0 sm:grid sm:gap-5 ",
                    props.columns ? "grid-cols-" + props.columns : "sm:grid-cols-2 lg:grid-cols-4 md:grid-cols-3 ",
                    "dark:divide-gray-800 mt-0 ",
                )}
            >
                <h2 className="sr-only" id="quick-links-title">
                    Quick links
                </h2>
                {props.actions.map((action, actionIdx) => (
                    <div
                        key={action.id + " " + actionIdx}
                        className={classNames(
                            // actionIdx === 0 ? "rounded-tl-lg rounded-tr-lg sm:rounded-tr-none" : "",
                            // actionIdx === 1 ? "sm:rounded-tr-lg" : "",
                            // actionIdx === actions.length - 2 ? "sm:rounded-bl-lg" : "",
                            // actionIdx === actions.length - 1 ? "rounded-bl-lg rounded-br-lg sm:rounded-bl-none" : "",
                            // action.bg,
                            "transform  rounded-lg ",
                            "relative    p-6",
                            " focus-within:ring-2 focus-within:ring-inset focus-within:ring-cyan-500",
                            action.disabled
                                ? "bg-gray-200 dark:bg-gray-600   "
                                : "bg-white dark:bg-gray-800 shadow-xl group hover:translate-x-0.5 hover:translate-y-0.5 hover:shadow hover:to-indigo-700 hover:from-cyan-500  dark:hover:from-cyan-800 dark:hover:to-indigo-900 hover:text-white dark:hover:bg-gray-900 ",
                            "bg-gradient-to-br ",
                            "transition-all",
                            "duration-100 ",
                        )}
                    >
                        <div>
                            <span
                                className={classNames(
                                    action.iconForeground,
                                    "rounded-lg inline-flex p-3 ring-1 ring-white  ring-opacity-10",
                                    "dark:ring-opacity-0 dark:group-hover:ring-opacity-20 h-11 w-11",
                                    action.disabled ? " bg-gray-300 dark:bg-gray-700" : action.iconBackground,
                                )}
                            >
                                <i
                                    className={
                                        "h-6 w-6 m-auto fa-fw  " +
                                        action.icon?.replaceAll("fal ", "fas ") +
                                        (action.disabled ? " text-gray-400 dark:text-gray-400" : "")
                                    }
                                    aria-hidden="true"
                                />
                            </span>
                        </div>
                        <div className="mt-8 ">
                            <h3 className="text-lg font-medium ">{action.name}</h3>
                            {action.href && (
                                <Link
                                    to={action.href ?? "/"}
                                    className={
                                        action.disabled ? "dark:text-gray-400" : "focus:outline-none hover:text-white"
                                    }
                                    style={action.disabled ? { pointerEvents: "none" } : undefined}
                                >
                                    {/* Extend touch target to entire panel */}
                                    <span className="absolute inset-0 " aria-hidden="true" />
                                    <p className="mt-2 text-sm opacity-70 pointer-events-none ">{action.description}</p>
                                </Link>
                            )}
                            {action.action && (
                                <a
                                    href="#"
                                    onClick={() => {
                                        if (action.action) {
                                            action.action(action.id ?? -1);
                                        }
                                    }}
                                    className={
                                        action.disabled ? "dark:text-gray-400" : "focus:outline-none hover:text-white"
                                    }
                                    style={action.disabled ? { pointerEvents: "none" } : undefined}
                                >
                                    {/* Extend touch target to entire panel */}
                                    <span className="absolute inset-0 hover:text-white" aria-hidden="true" />
                                    {/* {action.name} */}
                                    <p className="mt-2 text-sm opacity-70 pointer-events-none ">{action.description}</p>
                                </a>
                            )}
                        </div>
                        <span
                            className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-white duration-500"
                            aria-hidden="true"
                        >
                            <svg
                                className="h-6 w-6 "
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 24 24"
                            >
                                <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                            </svg>
                        </span>
                    </div>
                ))}
            </div>
        </section>
    );
}
export type ActionPanelEntry = {
    id: number;
    name: string;
    href?: string;
    action?: (id: number) => void;
    icon: string;
    iconForeground: string;
    iconBackground: string;
    description: string;
    bg: string;
    disabled?: boolean;
    extraData?: any;
};
type ActionsPanelProps = {
    actions: ActionPanelEntry[];
    columns?: number | string;
};
