import _ from "lodash";
import { useQuery } from "@tanstack/react-query";
import businessRuleGroupService from "../../../services/business-rule-group.service";
import businessRuleTypeService from "../../../services/business-rule-type.service";
import processflowRuleGroupService from "../../../services/processflow-rule-group.service";
import processflowService from "../../../services/processflow.service";
import { BusinessRule } from "../../../typings/api/business-rule";
import { BusinessRuleGroup } from "../../../typings/api/business-rule-group";
import { ProcessFlowProgressData } from "../../../typings/api/processflow-progress-data";
import { Entry } from "../../../layout/add-to-list";
import { useState } from "react";
import nl2br from "../../utilities/nl2br";
import { checkIfRuleGroupListPasses } from "../../../jason-proof-of-concept/processflows/actions/validate-entry";

export type ResourceType = "stage" | "step" | "field";

export type RuleTestResult = {
    passed: boolean;
    ruleGroupFailed?: BusinessRuleGroup;
    ruleFailed?: BusinessRule;
    ruleField?: string;
    isError?: boolean;
    message: string;
    rulePassed?: BusinessRule;
    results?: any;
    expected?: any;
    actual?: any;
};

export type DebugError = {
    message: string;
    details?: any;
    ruleGroupFailed?: BusinessRuleGroup;
    ruleFailed?: BusinessRule;
    ruleField?: string;
    id: number;
};

/*
 * This hook is used to determine if a business rule passes or not.
 * it is primarily used in the wizard to determine whether or not to show a stage/step/field.
 *
 */
export default function useRulePasses(processFlowGroupId: number) {
    const [debugErrors, setDebugErrors] = useState<DebugError[]>([]);
    const [errorId, setErrorId] = useState<number>(0);
    const processflowRuleGroupQuery = useQuery(["processflow-rule-groups", processFlowGroupId], async () => {
        const response = await processflowRuleGroupService.getAll();
        if (response) {
            return response.data;
        }
    });

    const entriesQuery = useQuery(["processflow-entries-with-rules", processFlowGroupId], async () => {
        const response = await processflowService.getAllByGroupIdWithRuleGroupsAndRules(Number(processFlowGroupId));
        // console.error("entriesQuery", response);
        if (response) {
            // alert(processFlowGroupId);
            return response.data;
        } else {
            console.error(response);
        }
    });

    const ruleTypeQuery = useQuery(["business-rule-types2"], async () => {
        const response = await businessRuleTypeService.getAll();
        if (response) {
            return response.data;
        } else {
            return [];
        }
    });

    const ruleQuery = useQuery(["ruleGroup"], async () => {
        const response = await businessRuleGroupService.getAllIncludingChildren();
        if (response) {
            // console.error(response.data[0]);
            return response.data;
        }
    });

    function checkIfRuleGroupPasses(
        ruleGroup: BusinessRuleGroup,
        progressData?: ProcessFlowProgressData | null,
    ): RuleTestResult {
        // const allPassed = [];
        for (const rule of ruleGroup.businessRules ?? []) {
            // console.error(rule);

            const ruleType = ruleTypeQuery.data?.find((type) => type.id === rule.ruleType);
            if (!ruleType) {
                // alert("Rule type not found");
                return {
                    passed: false,
                    isError: true,
                    ruleField: rule.fieldName,
                    ruleFailed: rule,
                    ruleGroupFailed: ruleGroup,
                    message: "Rule type not found",
                };
            }
            const val = progressData?.entriesByField?.[rule.fieldName]?.fieldValue;
            if ((ruleType.id !== 10 && val === "") || val === undefined || val === null) {
                // console.error("Entries!!!!",progressData?.entriesByField);
                return {
                    passed: false,
                    isError: true,
                    ruleField: rule.fieldName,
                    ruleFailed: rule,
                    ruleGroupFailed: ruleGroup,
                    message:
                        "Value not found for " +
                        ruleType.name +
                        " rule in field:" +
                        rule.fieldName +
                        " (looking in: " +
                        nl2br(JSON.stringify(progressData?.entriesByField, null, 2)) +
                        ")",
                };
            }

            // Check the value based on the rule type.
            switch (ruleType.dataType) {
                case "checkbox":
                    if (ruleType.id === 6) {
                        // Checkbox
                        if (rule.valueTriggerStart === "1") {
                            if (!val) {
                                if (ruleGroup.isAnd) {
                                    return {
                                        passed: false,
                                        ruleGroupFailed: ruleGroup,
                                        ruleFailed: rule,
                                        ruleField: rule.fieldName,
                                        message: "Checkbox rule failed (and)",
                                    };
                                }
                            } else if (!ruleGroup.isAnd) {
                                return {
                                    passed: true,
                                    message: "Checkbox rule passed (or)",
                                };
                            }
                        } else {
                            if (val !== "0") {
                                if (ruleGroup.isAnd) {
                                    return {
                                        passed: false,
                                        ruleField: rule.fieldName,
                                        ruleFailed: rule,
                                        ruleGroupFailed: ruleGroup,
                                        message: "Checkbox rule failed",
                                    };
                                }
                            } else if (!ruleGroup.isAnd) {
                                return {
                                    passed: true,
                                    message: "Checkbox rule passed",
                                };
                            }
                        }
                    }
                    break;
                case "yesNo":
                    if (ruleType.id === 7) {
                        if (rule.valueTriggerStart.toString() === "1") {
                            if (val.toString() !== "1") {
                                if (ruleGroup.isAnd) {
                                    return {
                                        passed: false,
                                        ruleField: rule.fieldName,
                                        ruleFailed: rule,
                                        ruleGroupFailed: ruleGroup,
                                        message: "Yes/No rule failed",
                                    };
                                }
                            } else if (!ruleGroup.isAnd) {
                                return {
                                    passed: true,
                                    message: "Yes/No rule passed with " + val,
                                    ruleField: rule.fieldName,
                                };
                            }
                        } else {
                            if (val.toString() !== "0") {
                                // console.error("Yes/No rule failed with value " + val+ " and trigger " + rule.valueTriggerStart);
                                if (ruleGroup.isAnd) {
                                    return {
                                        passed: false,
                                        ruleField: rule.fieldName,
                                        ruleFailed: rule,
                                        ruleGroupFailed: ruleGroup,
                                        message: "Yes/No rule failed",
                                    };
                                }
                            } else if (!ruleGroup.isAnd) {
                                return {
                                    passed: true,
                                    message: "Yes/No rule passed with " + val,
                                    ruleField: rule.fieldName,
                                };
                            }
                            // else {
                            // console.log("Yes/No passed (" + val + ")");
                            // }
                        }
                    }
                    break;
                case "selectBox":
                    if (ruleType.id === 8) {
                        if (val !== rule.valueTriggerStart) {
                            if (ruleGroup.isAnd) {
                                return {
                                    passed: false,
                                    ruleField: rule.fieldName,
                                    ruleFailed: rule,
                                    ruleGroupFailed: ruleGroup,
                                    message: "Select box rule failed",
                                };
                            }
                        } else if (!ruleGroup.isAnd) {
                            return {
                                passed: true,
                                ruleField: rule.fieldName,
                                rulePassed: rule,
                                ruleGroupFailed: ruleGroup,
                                message: "Select box rule passed and rule is or",
                            };
                        }
                    }
                    break;
                case "textfield":
                    // console.error(ruleType.id);
                    if (ruleType.id === 4) {
                        // Number higher than
                        if (Number(val) <= Number(rule.valueTriggerStart)) {
                            if (ruleGroup.isAnd) {
                                return {
                                    passed: false,
                                    ruleField: rule.fieldName,
                                    ruleFailed: rule,
                                    ruleGroupFailed: ruleGroup,
                                    message: "Number higher than rule failed",
                                };
                            }
                        } else if (!ruleGroup.isAnd) {
                            return {
                                passed: true,
                                message: "Number higher than rule passed",
                                ruleField: rule.fieldName,
                            };
                        }
                    } else if (ruleType.id === 5) {
                        // Number lower than
                        if (Number(val) >= Number(rule.valueTriggerStart)) {
                            if (ruleGroup.isAnd) {
                                return {
                                    passed: false,
                                    ruleField: rule.fieldName,
                                    ruleFailed: rule,
                                    ruleGroupFailed: ruleGroup,
                                    message: "Number lower than rule failed",
                                };
                            }
                        } else if (!ruleGroup.isAnd) {
                            return {
                                passed: true,
                                message: "Number lower than rule passed",
                                ruleField: rule.fieldName,
                            };
                        }
                    } else if (ruleType.id === 9) {
                        // Exact match
                        if (val !== rule.valueTriggerStart) {
                            // console.error("Exact match failed: ", val, rule.valueTriggerStart);
                            if (ruleGroup.isAnd) {
                                return {
                                    passed: false,
                                    ruleField: rule.fieldName,
                                    ruleFailed: rule,
                                    ruleGroupFailed: ruleGroup,
                                    message: "Exact match rule failed",
                                };
                            }
                        } else if (!ruleGroup.isAnd) {
                            return {
                                passed: true,
                                message: "Exact match rule passed",
                                ruleField: rule.fieldName,
                            };
                        }
                    } else if (ruleType.id === 10) {
                        // Has a number in it
                        // alert("Has a number in it not implemented");
                        if (ruleGroup.isAnd) {
                            if (isNaN(val as any) || val.length === 0) {
                                console.info("Empty value: ", val, rule.valueTriggerStart);
                                return {
                                    passed: false,
                                    ruleField: rule.fieldName,
                                    ruleFailed: rule,
                                    ruleGroupFailed: ruleGroup,
                                    message: "Is a number rule failed",
                                };
                            } else if (val !== "7") {
                                console.info("Not empty value: ", val, rule.valueTriggerStart);
                            }
                        } else {
                            console.error("Is a number rule passed: ", isNaN(val as any), val.length === 0, ruleGroup);
                            // only return immediately if there's nothing left to do
                            return {
                                passed: true,
                                message: "Is a number rule passed",
                                ruleField: rule.fieldName,
                            };
                        }
                    }
                    break;
                case "datePicker":
                    if (ruleType.id === 1) {
                        // Date between
                        if (val < rule.valueTriggerStart || val > rule.valueTriggerEnd) {
                            if (ruleGroup.isAnd) {
                                return {
                                    passed: false,
                                    ruleField: rule.fieldName,
                                    ruleFailed: rule,
                                    ruleGroupFailed: ruleGroup,
                                    message: "Date between rule failed",
                                };
                            }
                        } else if (!ruleGroup.isAnd) {
                            return {
                                passed: true,
                                message: "Date between rule passed",
                                ruleField: rule.fieldName,
                            };
                        }
                    } else if (ruleType.id === 2) {
                        // Date after
                        if (val < rule.valueTriggerStart) {
                            if (ruleGroup.isAnd) {
                                return {
                                    passed: false,
                                    ruleField: rule.fieldName,
                                    ruleFailed: rule,
                                    ruleGroupFailed: ruleGroup,
                                    message: "Date after rule failed",
                                };
                            }
                        } else if (!ruleGroup.isAnd) {
                            return {
                                passed: true,
                                message: "Date after rule passed",
                                ruleField: rule.fieldName,
                            };
                        }
                    } else if (ruleType.id === 3) {
                        // Date before
                        if (val > rule.valueTriggerStart) {
                            if (ruleGroup.isAnd) {
                                return {
                                    passed: false,
                                    ruleField: rule.fieldName,
                                    ruleFailed: rule,
                                    ruleGroupFailed: ruleGroup,
                                    message: "Date before rule failed",
                                };
                            }
                        } else if (!ruleGroup.isAnd) {
                            return {
                                passed: true,
                                message: "Date before rule passed",
                                ruleField: rule.fieldName,
                            };
                        }
                    }
                    break;
            }
        }

        // And rules that fail will not get here (will return false)
        // Or rules that pass will not get here (will return true)
        // If we get here, all rules passed
        return {
            passed: ruleGroup.isAnd === 1,
            ruleField: "None",
            ruleFailed: undefined,
            ruleGroupFailed: ruleGroup,
            message: ruleGroup.name + " " + (ruleGroup.isQualify ? "Qualifier" : "Disqualifier") + " Nothing matched",
        };
    }

    // function checkIfRuleGroupListPasses(businessRuleGroups: BusinessRuleGroup[], progressData?: ProcessFlowProgressData | null, isOrCheck?: boolean): RuleTestResult {
    //   // console.error("Checking if rule group passes with " + businessRuleGroups?.length + " groups");
    //   // Go through each rule group and check if the rule passes.
    //   const results = [];
    //   for (const ruleGroup of businessRuleGroups ?? []) {
    //     // alert("1");
    //     // console.log("rg",ruleGroup);
    //     const result = checkIfRuleGroupPasses(ruleGroup, progressData);

    //     if (ruleGroup.isQualify === 0) {
    //       // invert if it is not a qualification rule - ie. if it is a disqualify rule
    //       result.passed = !result.passed;
    //     }
    //     if (!result.passed && !isOrCheck) {
    //       // alert("Rule failed: " + result.message);
    //       return result;
    //     }
    //     results.push(result);
    //   }
    //   return {
    //     passed: true,
    //     message: "All rules passed",
    //     results
    //   };
    // }

    function checkIfStepPasses(stepId: number, progressData?: ProcessFlowProgressData | null): RuleTestResult {
        // Find the rule groups (and rules) for the step.
        if (!(entriesQuery.isSuccess && ruleTypeQuery.isSuccess)) {
            // console.error("Not all queries are ready");
            return {
                passed: false,
                isError: true,
                message: "Not all queries are ready",
            };
        }
        // Find the entry for the step.
        const stepToCheck = entriesQuery.data?.find((entry) => entry.id === stepId);

        if (!stepToCheck) {
            console.error("Step not found " + stepId, entriesQuery.data, processFlowGroupId);
            return {
                passed: false,
                isError: true,
                message: "Step not found ",
            };
        }

        if (stepToCheck.businessRuleGroups?.length === 0) {
            return {
                passed: true,
                message: "No rule groups",
            };
        }

        const result = checkIfRuleGroupListPasses({
            businessRuleGroups: stepToCheck.businessRuleGroups ?? [],
            businessRuleTypes: ruleTypeQuery.data ?? [],
            data: progressData,
            ruleGroupsAreOr: stepToCheck.ruleGroupsAreOr === 1,
        });

        // console.log({task: "Result "+stepToCheck.title , result, progressData});
        // console.log("Result: ", result, progressData);
        return result;
    }

    function checkIfStagePasses() {
        return false;
    }

    function checkIsLoadingRuleChecks() {
        return (
            entriesQuery.isLoading ||
            processflowRuleGroupQuery.isLoading ||
            ruleQuery.isLoading ||
            ruleTypeQuery.isLoading
        );
    }

    // WRONG (read after) : You only need one function - check if rule passes. It could be run in the context
    // of a step, a stage or a field. The function would then check if the rule passes.
    // You have the rules and rule groups as relations of the steps or stages, so you can
    // just check if the rule passes.
    //
    // The reality is that checking rule stuff should be done here. There's already more than enough
    // code in the process flow component. The process flow component should only be concerned with
    // the process flow itself. It should not be concerned with the rules. The rules should be
    // checked here and the process flow component should only be concerned with the result of the
    // check. (That was 70% written by CoPilot)
    return { checkIfStepPasses, checkIfStagePasses, checkIsLoadingRuleChecks, debugErrors };
}
