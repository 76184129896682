import { useCallback, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useRecoilState, useRecoilValue } from "recoil";
import positionAtom, { Position } from "../../../atoms/positionAtom";
import progressDataAtom from "../../../atoms/progressDataAtom";
import userAtom from "../../../atoms/userAtom";
import { Entry } from "../../../layout/add-to-list";
import PrintPre from "../../../layout/print-pre";
import TableNeogen from "../../../layout/table-neogen";
import processflowService from "../../../services/processflow.service";
import { ClearERCUser } from "../../../typings/api/clear-erc-user";
import { ProcessFlow } from "../../../typings/api/processflow";
import { ProcessFlowProgressData } from "../../../typings/api/processflow-progress-data";
import useRulePasses from "../../business-rules/hooks/use-rule-passes";
import Loader2 from "../../utilities/Loader2";
import nl2br from "../../utilities/nl2br";
import Stage from "./stage";
import useStep from "../hooks/use-step";
import WizardTabs from "./wizard-tabs";
import { useParams } from "react-router-dom";
// import { DrawField } from "./draw-field";
import { useGetMap } from "./get-map";
import useFieldChanged, { FieldChangeParams } from "../hooks/use-field-changed";
import processflowProgressIdAtom from "../../../atoms/processflowProgressIdAtom";
import { ProcessFlowStage } from "../../../typings/api/processflow-stage";
import pfDebugAtom from "../../../atoms/pfDebugAtom";
import Badge from "../../../layout/badge";
import CheckBoxNeoGenControlled from "../../../layout/checkbox-controlled";
import FileUploadWidget from "../../../layout/file-upload-widget";
import InputControlled from "../../../layout/input-controlled";
import RadioButtonNeoGenControlled from "../../../layout/radiobutton-neogen-controlled";
import SelectNeoGen from "../../../layout/select-neogen";
import TextAreaNeoGenControlled from "../../../layout/text-area-controlled";
import { FileUpload } from "../../../typings/api/file-upload";
import { calculate } from "../../utilities/calculate";
import CalendarWidget from "../../utilities/calendar-widget";
import useNavigateProcess from "../hooks/use-navigate-process";
import ProcessflowButton from "./processflow-button";
import states from "../../../sections/utilities/states.json";
import Swal from "sweetalert2";
import useStepValidation from "../hooks/use-step-validation";
import processflowStageService from "../../../services/processflow-stage.service";
// import the react-json-view component
import ReactJson from "react-json-view";
import dateIsValid from "../../utilities/date-is-valid";
// import checkIfFieldPasses from

export type FormFillProps = {
    isPublic: boolean | undefined;
    showTabs: boolean;
    processFlow?: ProcessFlow;
    id?: number;
    showStages?: boolean;
};

/**
 * The FormFill component is used to fill out the form for a process flow.
 * @param {FormFillProps} props - The props for the component.
 * @returns A React component that can be rendered.
 */
export default function FormFill(props: FormFillProps): JSX.Element {
    const [processFlowProgressId, setProcessflowProgressId] = useRecoilState<number>(processflowProgressIdAtom);
    const user = useRecoilValue<ClearERCUser>(userAtom);
    const [position, setPosition] = useRecoilState<Position>(positionAtom);
    const [display, setDisplay] = useState<any[]>([]);
    const [checkStepValidation] = useStepValidation();
    const processFlowGroup = 2; // TODO: this should not be hardcoded
    const getMap = useGetMap();
    if (!user.id) {
        Swal.fire({
            title: "Error",
            text: "You must be logged in to access this page." + JSON.stringify(user),
            icon: "error",
            confirmButtonText: "OK",
        }).then(() => {
            window.location.href = "/login";
            return;
        });
    }
    let { processflow: id } = useParams();
    // console.log("id1", id);
    if (!id) {
        id = (props.id ?? "-1").toString();
        // console.log("id2", id, props);
    }
    // console.log("id", id);
    const { fieldChanged } = useFieldChanged(user.id ?? "", Number(id));
    // const [progressData, setProgressData] = useRecoilState<ProcessFlowProgressData>(progressDataAtom);
    const { isLoading, getStepFromId } = useStep({ groupId: Number(id) });
    const [currentEntry, setCurrentEntry] = useState<ProcessFlow | undefined>(undefined);
    // const [pfDebug, setPfDebug] = useState<any>(undefined);
    // const {fieldChanged } = useCurrentProgress(Number(id), props.isPublic??false);
    const { debugErrors, checkIfStepPasses, checkIsLoadingRuleChecks } = useRulePasses(Number(id));

    const [progressData, setProgressData] = useRecoilState<ProcessFlowProgressData>(progressDataAtom);
    const [pfDebug, setPfDebug] = useRecoilState<boolean>(pfDebugAtom);
    // const [position, setPosition] = useRecoilState<Position>(positionAtom);
    const { nextStage, nextStep, previousStage, previousStep } = useNavigateProcess(Number(id));

    const stageQuery = useQuery(["processflow-stages", "getAllByGroupId", processFlowGroup], async () => {
        console.log(processFlowGroup);
        const response = await processflowStageService.getAllByGroupIdIncludingRuleGroups(processFlowGroup);
        if (response) {
            return response.data;
        } else {
            Swal.fire({
                title: "Error",
                text: "There was an error getting the stages for this process flow group",
                icon: "error",
                confirmButtonText: "OK",
            });
            return [];
        }
    });

    /**
     * Queries the process flow entries for the current stage and group.
     *
     * @param {number} currentStage - the current stage of the process flow
     * @param {string} id - the id of the group that the process flow is for
     * @returns {ProcessFlow[]} - the process flow entries for the current stage and group
     */
    const allEntriesForStageAndGroupQuery = useQuery(["allEntriesForStageAndGroupQuery"], async () => {
        console.error("run run ");
        // if (position.stageId === -1) {
        //     console.error("No current stage");
        //     return;
        // }

        const response = await processflowService.getAllByOrderForStageAndGroup(position.stageId, Number(id));
        if (response && response.data) {
            // console.info({ response })
            return response.data;
        }
        // return [];
    });

    // async function sleep(ms: number) {
    //     return new Promise(res => setTimeout(res, ms));
    // }

    const startAgain = useCallback(async () => {
        const found = false;
        console.error("Start again");
        // while (!found) {
        const response = await processflowService.getAllByOrderForGroupWithRuleGroupsAndRules(Number(id));
        if (response && response.data) {
            console.info({ response });
            const step = response.data[0].id;
            const stage = response.data[0].stage;
            // const response2 = await processflowService.getAllByOrderForStageAndGroup(Number(stage), Number(id));
            // if (response2 && response2.data) {
            //     console.info({ response2 });
            //     // return response2.data;
            // } else {
            //     console.error("Didn't get any data ");
            // }
            setPosition((c: Position) => ({ stepId: Number(step), stageId: Number(stage) }));
        }

        // }
        // If we still didn't find it in the while loop, then we need to return an error
        // if (!found) {
        // startAgain();
        // }
    }, [id, setPosition]);

    useEffect(() => {
        // This looks weird but the idea is to check
        // if we actually have a step with the id we are looking for
        const step = getStepFromId(position.stepId);
        // if we don't then we need to get the first step for the stage we're in
        // If we don't find the stage either then move to the first step in the first stage
        if (!step) {
            console.error("No step found FOR " + position.stepId);
            console.error("Step not found");
            startAgain();
            // return;
        } else {
            console.error("Step found", step);
            setCurrentEntry(step);
        }

        // }
        // return;

        // Go to the first stage and the first entry
        // console.error(s)
    }, [getStepFromId, isLoading, position.stepId, startAgain]);
    // ], [];

    // useEffect(() => {
    //     setCurrentEntry(display.find(e => e.id === position.stepId));
    // }, [allEntriesForStageAndGroupQuery.data, display, position.stepId]);

    // const {getMap} = useCurrentProgress(Number(7), false);
    useEffect(() => {
        getMap(user.id ?? "", (7).toString()).then((map: ProcessFlowStage[] | null) => {
            console.error({ map });
            if (map) {
                setDisplay(map);
            }
        });
    }, [getMap, user.id]);

    //   function drawField(entry: Entry, idx: number, fieldChanged: FieldChangeParams, isPublic: boolean, processFlowGroupId: number) {

    //     if (!fieldChanged) {
    //       console.error("fieldChangedParam is null");
    //       return null;
    //     }

    //     // const result = checkIfFieldPasses(progressData, entry);
    //     // if (!result.passed && pfDebug) {
    //     //   // console.error({ result });
    //     //   // return;
    //     //   return <>
    //     //     <TableNeogen
    //     //       headerBg="bg-red-200 dark:bg-red-700"
    //     //       compact
    //     //       entries={[checkIfFieldPasses(progressData, entry)]}
    //     //       formatters={[
    //     //         {
    //     //           field: "passed",
    //     //           type: "Boolean",
    //     //         },
    //     //         {
    //     //           field: "isError",
    //     //           type: "Boolean",
    //     //         },
    //     //       ]}
    //     //     />
    //     //   </>;
    //     // }
    //     // const errors = checkStepValidation(<entry></entry>, progressData);
    //     // console.log(errors);

    //     switch (entry.widthOf300Cols) {
    //       case "300":
    //         entry.widthOf300Cols = "  w-full pr-5";
    //         break;
    //       case "150":
    //         entry.widthOf300Cols = "  w-1/2 inline-block pr-5 ";
    //         break;
    //       case "100":
    //         entry.widthOf300Cols = "  align-top w-1/3 inline-block pr-5 ";
    //         break;
    //       case "75":
    //         entry.widthOf300Cols = "  w-1/4 inline-block pr-5 ";
    //         break;
    //       case "60":
    //         entry.widthOf300Cols = "  w-1/5 inline-block pr-5 h-full";
    //         break;
    //       default:
    //         entry.widthOf300Cols = "  w-full inline-block pr-5 ";
    //         break;
    //     }

    //     // entry.widthOf300Cols+= " my-1 border-y border-gray-300 p-5";
    //     entry.widthOf300Cols += " my-1 top-0  left-0 h-full ";
    //     let button;
    //     if (entry.type === "button") {
    //       try {
    //         button = JSON.parse(entry.value);
    //       } catch (e) {
    //         console.error(e);
    //         // return;
    //       }
    //     }

    //     switch (entry.type) {
    //       case "checkbox":
    //         return (
    //           <div key={idx} className={" " + (entry.widthOf300Cols ?? "w-full") + " mb-5"}>

    //             <CheckBoxNeoGenControlled
    //               setValue={(e: React.ChangeEvent<HTMLInputElement>) => {

    //                 fieldChanged("checkbox", e.currentTarget.checked, entry.field, false);

    //               }}
    //               value={progressData?.entriesByField?.[entry.field]?.fieldValue}
    //               name={entry.field}
    //               label={entry.value}
    //               description={entry.description}
    //               helpDocument={entry.helpDocument}
    //             />
    //           </div>
    //         );
    //       case "state":
    //         console.log(progressData?.entriesByField?.[entry.field] ?? "");
    //         return (
    //           <div key={idx} className={" " + (entry.widthOf300Cols ?? "w-full") + " mb-5"}>
    //             <SelectNeoGen
    //               // helpDocument={entry.helpDocument}
    //               // multiple= {true}
    //               onChange={(e: (string | number)) => {
    //                 // console.error({ state: e.toString(), field: stateEntry.field, currentEntry });
    //                 fieldChanged("state", e.toString(), entry.field, false);
    //               }}
    //               value={progressData?.entriesByField?.[entry.field]?.fieldValue}
    //               options={states}
    //               // name={textfieldEntry.field}
    //               label={entry.value}
    //             // description={textfieldEntry.description}
    //             />
    //             <p className='text-sm text-gray-500'>{entry.description}</p>
    //           </div>
    //         );
    //       case "textarea":
    //         return (
    //           <div key={idx} className={" " + (entry.widthOf300Cols ?? "w-full") + " mb-5"}>
    //             <TextAreaNeoGenControlled
    //               setValue={(e: (string)) => {
    //                 fieldChanged("textarea", e, entry.field, false);
    //               }}
    //               value={progressData?.entriesByField?.[entry.field]?.fieldValue}
    //               name={entry.field}
    //               label={entry.value}
    //               // helpDocument={entry.helpDocument}
    //               description={entry.description}
    //             // errors={["This sucks"]}
    //             />
    //           </div>
    //         );
    //       case "textfield":
    //         return (
    //           <div key={idx} className={" " + (entry.widthOf300Cols ?? "w-full") + " self-start"}>
    //             {/* <PrintPre>{ entry.field}</PrintPre> */}
    //             {/* <PrintPre>{ progressData?.entriesByField?.[entry.field]?.fieldValue ?? ""}</PrintPre> */}
    //             <InputControlled
    //               setValue={(e: (string)) => {
    //                 // console.log(e);
    //                 fieldChanged("textfield", e, entry.field, false);
    //               }}
    //               required={entry.required}
    //               password={entry.password}
    //               type={entry.password ? "password" : "text"}
    //               value={progressData?.entriesByField?.[entry.field]?.fieldValue ?? ""}
    //               name={entry.field}
    //               label={entry.value}
    //               helpDocument={entry.helpDocument}
    //               description={entry.description}
    //             // errors={["This sucks"]}
    //             />
    //           </div>
    //         );
    //       case "valueCalculator":

    //         // Value Calculators produce values that you can display in a wysiwyg section.
    //         // They are not used to generate a response. Otherwise you would just see the number

    //         break;
    //       case "yesNo":
    //         return (
    //           <div key={idx} className={" " + (entry.widthOf300Cols ?? "w-full") + "  mb-5"}>
    //             <RadioButtonNeoGenControlled
    //               onChange={(e: (string | number)) => {
    //                 // console.log(e);
    //                 fieldChanged("yesNo", e.toString(), entry.field, false);
    //               }}
    //               value={progressData?.entriesByField?.[entry.field]?.fieldValue}
    //               options={[
    //                 { id: "1", name: "Yes" },
    //                 { id: "0", name: "No" },
    //               ]}
    //               // name={textfieldEntry.field}
    //               id={entry.field}
    //               label={entry.value}
    //               helpDocument={entry.helpDocument}
    //             // errors={["This sucks"]}
    //             // description={textfieldEntry.description}
    //             />
    //             {/* <PrintPre>
    //                             {progressData?.questionResponses.find(e => e.stepId === Number(position.step))?.entriesByField}
    //                         </PrintPre> */}
    //             <p className='text-sm text-gray-500'>{entry.description}</p>
    //           </div>
    //         );
    //       case "selectBox":
    //         return (

    //           <div key={idx} className={" " + (entry.widthOf300Cols ?? "w-full") + "  mb-5 "}>
    //             {/* <PrintPre>
    //                             {entry}
    //                         </PrintPre> */}
    //             <RadioButtonNeoGenControlled
    //               onChange={(e: (string | number)) => {
    //                 // console.log(e);
    //                 // alert(e)
    //                 fieldChanged("selectBox", e.toString(), entry.field, false);
    //                 // console.log("selectBox", e.toString(), entry.field);
    //               }}
    //               value={progressData?.entriesByField?.[entry.field]?.fieldValue}

    //               options={entry.selectBoxEntries?.map((selectBoxEntry: any) => {
    //                 return { id: selectBoxEntry, name: selectBoxEntry };
    //               })
    //               }
    //               helpDocument={entry.helpDocument}
    //               id={entry.field}
    //               label={entry.value}
    //             // errors={["This sucks"]}
    //             // description={textfieldEntry.description}
    //             />
    //             <p className='text-sm text-gray-500'>{entry.description}</p>
    //           </div>
    //         );
    //       case "fileUpload":
    //         return (
    //           <div key={idx} className={" " + (entry.widthOf300Cols ?? "w-full") + " "}>
    //             <FileUploadWidget
    //               fileDetails={progressData?.entriesByField?.[entry.field]?.fieldValue}

    //               helpDocument={entry.helpDocument}
    //               name={entry.field}
    //               label={entry.value}
    //               setFileDetails={(fileDetails: FileUpload) => {
    //                 fieldChanged("fileUpload", fileDetails, entry.field, false);
    //               }}
    //             />
    //             <p className='text-sm text-gray-500'>{entry.description}</p>
    //           </div>
    //         );
    //       case "button":
    //         return (
    //           <div key={idx} className={"inline-block mr-5 mb-5" + " "}>
    //             {/* <div key={idx} className={"inline-block " + (entry.widthOf300Cols ?? " ") + " "}> */}
    //             {/* <PrintPre> */}
    //             {/* {button} */}
    //             {/* </PrintPre> */}
    //             <ProcessflowButton
    //               button={button}
    //               type={button.colorType !== "custom" ? button.colorType : undefined}
    //               size={button.size}
    //               nextStep={nextStep}
    //               previousStep={previousStep}
    //               nextStage={nextStage}
    //               previousStage={previousStage}
    //               progressData={progressData}
    //               // onClick={() => {
    //               //     // console.log("button click\?ed");
    //               //     alert("hi");
    //               // }}
    //               isPublic={isPublic}
    //               icon={(button.action === "nextStep" && "fas fa-chevron-right")
    //                                 || (button.action === "previousStep"&&"fas fa-chevron-left")
    //                                 || (button.action === "nextStage"&&"fas fa-chevron-right")
    //                                 || (button.action === "previousStage"&&"fas fa-chevron-left")
    //                                 || (button.action === "submit"&&"fal fa-check")
    //                                 || (button.action === "save"&&"fal fa-save")
    //                                 || (button.action === "cancel"&&"fal fa-times")
    //                                 || (button.action === "reset"&&"fal fa-undo")
    //                                 || (button.action === "custom"&&button.icon)
    //               }
    //               iconAlign={["previousStep", "previousStage"].includes(button.action) ? "left" : "right"}
    //               text={button.label}
    //             // text={pfDebug?button.label +` Typo:${button.action}`: button.label}
    //             />
    //             {pfDebug &&
    //               <Badge
    //                 color="green"
    //                 label={button.action}
    //               />

    //             }
    //           </div>

    //         );
    //       case "wysiwyg": {  // An HTML string

    //         // In the HTML sections you can click a button to insert a field's value
    //         // Basically the way it works is that you have a button that inserts the '
    //         // field name inside double curly braces. When we are displaying the HTML
    //         // we replace the double curly braces with the actual value of the field.
    //         // This could be the value the customer entered or it could be a calculation
    //         // of other fields.
    //         //
    //         // Replace {{fieldName}} with the value of the field with the same name
    //         // across all the questions in the current progress flow group.
    //         console.info("Drawing: ", entry);

    //         // eslint-disable-next-line no-case-declarations
    //         const html = entry.value.replace(/{{(.*?)}}/g, (_match: string, fieldFound: string) => {
    //           console.log("We found the field: " + fieldFound);
    //           console.log("progressData?.questionResponses: ", progressData?.entriesByField);

    //           // Look for the field value in the current step. If it's not there then
    //           // either the customer hasn't entered it yet or it's not in the current
    //           // step. So we look for it in the previous steps.
    //           // The other option is that the field is a calculation, in which case
    //           // we need to look for the field values to calculate it.
    //           // The val variable will hold the value of the field and its details.
    //           const val: Entry = progressData?.entriesByField?.[fieldFound];
    //           // console.error("val for "+fieldFound+": ",val);

    //           // If the field type is a state, return the long form state name.
    //           if (val?.type === "state") {
    //             return states.find((s: any) => s.id === val.fieldValue)?.name ?? "";
    //           }

    //           // If the fiield type is a checkbox, return it as Yes or No
    //           if (val?.type === "checkbox") {
    //             return val.fieldValue ? "Yes" : "No";
    //           }

    //           // If we didn't find the field value it might just have not been filled out yet.
    //           // We should know from the field type if it's a calculation or not.

    //           // First look through all entries and see if we can find the field name.
    //           // console.error({ pp: JSON.parse(entriesQuery.data?.[0].entries ?? "[]") });
    //           const field: Entry | undefined = JSON.parse(allEntriesForStageAndGroupQuery.data?.[0]?.entries ?? "[]").find((e: any) => {
    //             console.error({ f1: e.field, f2: fieldFound });
    //             return e.field === fieldFound;
    //           });
    //           // console.error({field, allEntriesForStageAndGroupQuery: allEntriesForStageAndGroupQuery.data?.[0]?.entries});
    //           if (!val && field?.type === "valueCalculator") {
    //             // console.error("We didn't find the field value but it's a calculation so we need to calculate it.");
    //             // console.error({val, ebf:progressData?.entriesByField, fieldFound});
    //             try {
    //               let total = 0;
    //               // alert("1");
    //               // console.log({ entry, eq: entriesQuery.data?.find(e => JSON.parse(e.entries ?? "")) });
    //               const parsed = allEntriesForStageAndGroupQuery.data?.map((e: any) => {
    //                 try {
    //                   // console.error({en: e.entries});
    //                   return JSON.parse(e.entries ?? "");
    //                 } catch (error) {
    //                   console.error(error, e.entries);
    //                   return {};
    //                 }
    //               });
    //               console.log({ parsed });
    //               const found = parsed?.find((p: any) => p?.find((e: any) => e.id === fieldFound))?.find((e: any) => e.id === fieldFound).value;
    //               if (found) {
    //               // console.log({ xx: (found) });

    //                 for (const calculation of JSON.parse(found)) {
    //                   // console.log({ calculation });
    //                   let val1 = 0;
    //                   let val2 = 0;
    //                   if (calculation.field1 === "**num") {
    //                     val1 = calculation.field1number;
    //                   } else {
    //                     val1 = progressData?.entriesByField?.[calculation.field1]?.fieldValue;
    //                   }
    //                   if (calculation.field2 === "**num") {
    //                     val2 = calculation.field2number;
    //                   } else {
    //                     val2 = progressData?.entriesByField?.[calculation.field2]?.fieldValue;
    //                   }
    //                   const opResult = calculate(val1, val2, calculation.operator);
    //                   // console.log({ val1, val2, opResult });
    //                   total += opResult;
    //                 }
    //               }
    //               // console.log({ parsed, fieldName, found },);
    //               // const eq = JSON.parse(entriesQuery.data?.entries??"");
    //               return (Number.isNaN(total) ? 0 : total).toLocaleString();
    //             } catch (e) {
    //               console.trace({ e });
    //               return "(<></>)";
    //             }
    //           }
    //           return val?.fieldValue ?? "";
    //         }).replace(/\n/g, "<br />");

    //         return (
    //           <div key={idx} className={" " + (entry.widthOf300Cols ?? "w-full") + " mb-5"}>
    //             {/* <PrintPre>
    //                             {entry.value.replace(/\\"/g, "\"")}
    //                         </PrintPre> */}
    //             <div
    //               className="ql-snow ql-read-only "
    //               dangerouslySetInnerHTML={{ __html: html }}
    //             />

    //           </div>
    //         );
    //       }
    //       case "datePicker": {
    //       // const currentValue = progressData?.entriesByField?.[entry.field] as (Date|number|string);
    //       // let date: (Date | number | string) = new Date(progressData?.entriesByField?.[entry.field]);
    //       // alert(progressData?.entriesByField?.[entry.field]);
    //       // if (!dateIsValid(date)) {
    //       // alert("Invalid date: " + date+" "+progressData?.entriesByField?.[entry.field]);
    //       // alert("Invalid date: " + date+" "+progressData?.entriesByField?.[entry.field]);
    //       // const date = Date.parse(progressData?.entriesByField?.[entry.field] as string);
    //       // alert("Invalid date: " + date+" "+progressData?.entriesByField?.[entry.field]);
    //       // }

    //         // alert(date);
    //         // helpDocument={entry.helpDocument}
    //         // console.log(currentValue);
    //         // if (currentValue) {
    //         // console.log(new Date(currentValue));
    //         // if (typeof currentValue === Date) {
    //         // continue;;
    //         // }
    //         // if (typeof currentValue === Date) {
    //         //     currentValue;
    //         // }
    //         // if (typeof Date === Date) {
    //         //     currentValue;
    //         // }
    //         // console.error({currentValue});
    //         // date = Date.parse(currentValue.toString() );
    //         // }

    //         return (

    //           <div key={idx} className={" " + (entry.widthOf300Cols ?? "w-full") + " "}>
    //             <CalendarWidget
    //               onChange={(e: Date) => {
    //                 // console.log(e);
    //                 // alert(e);
    //                 entry.value = progressData?.entriesByField?.[entry.field];
    //                 fieldChanged("datePicker", e, entry.field, false);
    //               }}
    //               value={progressData?.entriesByField?.[entry.field]?.fieldValue && new Date(progressData?.entriesByField?.[entry.field].fieldValue)}
    //               label={entry.value}
    //               description={entry.description}

    //             />
    //           </div>
    //         );
    //       }
    //       default:
    //         return (
    //           <div>
    //             <PrintPre>
    //               {entry}
    //             </PrintPre>
    //             { }
    //           </div>
    //         );
    //     }

    //   }

    return (
        <>
            {isLoading ? (
                <Loader2 />
            ) : (
                <>
                    {/* <PrintPre>{currentEntry}</PrintPre> */}
                    <div className="mt-0 flex-grow max-w-xs">
                        <Stage processFlowGroupId={Number(id)} isPublic={props.isPublic} />
                        {/* <PrintPre>{display}</PrintPre> */}
                    </div>

                    <div className="mt-0  flex-grow  overflow-none">
                        <form className="space-y-0 ">
                            {props.showTabs !== false && <WizardTabs />}
                            <div className="flex items-start  justify-end">
                                <div className="flex items-center">
                                    {currentEntry?.details && currentEntry.details.trim().length > 0 && (
                                        <div>
                                            <p className="mt-0 text-sm text-gray-500 mb-5">
                                                {nl2br(currentEntry.details.toString())}
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="flex justify-start items-start content-start ">
                                <div className="  w-full h-full relative bg-white p-5 dark:bg-gray-800 rounded-xl ">
                                    {JSON.parse(currentEntry?.entries ?? "[]").map(
                                        (e: Entry, idx: number) => {
                                            // {
                                            // (currentEntry?.entries ?? []).map((e: any, idx: number) => {
                                            // if (checkIfFieldPasses(progressData, e).passed) {
                                            //   return (
                                            //     <React.Fragment key={idx}>
                                            //       {/* <PrintPre> { e}</PrintPre> */}
                                            //       {drawField(e, idx, fieldChanged, props.isPublic ?? false, Number(id))}
                                            //     </React.Fragment>
                                            //   );
                                            // }
                                            // if (pfDebug) {
                                            //     return (
                                            //         <React.Fragment key={idx}>
                                            //             {/* </PrintPre> */}
                                            //             {Object.keys(checkIfFieldPasses(progressData, e).ruleFailed ?? {}).map((r: any) => {
                                            //                 return (
                                            //                     <div key={r.id}>
                                            //                         <div className="flex items-center">
                                            //                             <div className="flex-shrink-0">
                                            //                                 {/* <PrintPre>{r}</PrintPre> */}
                                            //                                 {/* {e.ruleGroups?.map(rg => {
                                            //                                     return (
                                            //                                         <div key={rg.id}>
                                            //                                             <PrintPre>{rg}</PrintPre>
                                            //                                         </div>
                                            //                                     );
                                            //                                 })}
                                            //                                 <PrintPre>{r.ruleFailed}</PrintPre> */}
                                            //                             </div>
                                            //                         </div>
                                            //                     </div>
                                            //                 );
                                            //             })}
                                            // if (pfDebug) {
                                            //   return (<ReactJson
                                            //     key={idx}
                                            //     src={[checkIfFieldPasses(progressData, e)]}
                                            //     theme={"ocean"}
                                            //   />);
                                            // }
                                        },

                                        //             <ReactJson
                                        //                 src={[checkIfFieldPasses(progressData, e)]}
                                        //                 theme={"ocean"}
                                        //             />
                                        //             {/* <TableNeogen
                                        //                 compact
                                        //                 entries={[checkIfFieldPasses(progressData, e)]}
                                        //                 headerBg="bg-blue-200 dark:bg-blue-700"
                                        //                 formatters={[
                                        //                     {
                                        //                         field: "passed",
                                        //                         type: "Boolean",
                                        //                     },
                                        //                     {
                                        //                         field: "isError",
                                        //                         type: "Boolean",
                                        //                     },
                                        //                 ]}
                                        //             /> */}
                                        //         </React.Fragment>
                                        //     );
                                        // }
                                        /*else if (pfDebug) {
                                            return (
                                                <React.Fragment key={idx}>
                                                    <TableNeogen
                                                        compact
                                                        entries={[checkIfFieldPasses(progressData, e)]}
                                                        headerBg="bg-blue-200 dark:bg-blue-700"
                                                        formatters={[
                                                            {
                                                                field: "passed",
                                                                type: "Boolean",
                                                            },
                                                            {
                                                                field: "isError",
                                                                type: "Boolean",
                                                            },
                                                        ]}
                                                    />
                                                </React.Fragment>
                                            );
                                        }*/
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                </>
            )}
        </>
    );
}
