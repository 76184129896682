/**
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 9/23/20, 7:36 PM
 *
 */

import { AxiosResponse } from "axios";
import { Company } from "../typings/api";
import APIService from "./api.service";
import authService from "./auth.service";
import AuthService from "./auth.service";

export type MondaySyncFields = {
    id?: number;
    theirFieldName: string;
    fieldTitle?: string;
    used: number;
    ourFieldName?: string;
    ourSource?: string;
    sendToMonday?: boolean;
    sendToErc?: boolean;
};

class MondaySyncFieldsService extends APIService<MondaySyncFields> {
    constructor() {
        super("monday-sync-fields");
    }
}

export default new MondaySyncFieldsService();
