import React, { useState } from "react";
import ModalDialog from "../../../../layout/modal-dialog";
import TextAreaNeoGen from "../../../../layout/text-area-neogen";

import SelectNeoGen from "../../../../layout/select-neogen";
import InputControlled from "../../../../layout/input-controlled";
import mondaySyncFieldsService, { MondaySyncFields } from "../../../../services/monday-sync-fields.service";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { de } from "date-fns/locale";

type AddProcessflowEntryProps = {
    show: boolean;
    close: any;
    details?: any;
};

type Field = {
    id: number;
    field: MondaySyncFields;
};

export default function AddProcessflowEntry({ show, close, details }: AddProcessflowEntryProps) {
    const [fieldName, setFieldName] = useState("");
    const [fieldType, setFieldType] = useState("");
    const cache = useQueryClient();
    const ourFieldMutation = useMutation({
        mutationFn: async (data: Field) => {
            data.field.ourFieldName = fieldName;

            const { id, field } = data;
            await mondaySyncFieldsService.update(id, field);
            cache.invalidateQueries(["mondaySyncFields"]);
        },
    });
    const handleSubmit = () => {
        console.log({ fieldName, fieldType, details });
        ourFieldMutation.mutate({
            id: details.id,
            field: {
                ourFieldName: fieldName,
                theirFieldName: details.name,
                used: 1,
            },
        });
        close();
    };

    return (
        <>
            <ModalDialog
                show={show}
                close={() => close(false)}
                title="Add Processflow Entry"
                okAction={() => handleSubmit()}
            >
                <div className="flex flex-col mb-8">
                    <InputControlled
                        label="Name"
                        type="text"
                        // placeholder="Name"
                        value={fieldName}
                        onChange={(e: string) => setFieldName(e)}
                    />
                    <SelectNeoGen
                        label="Type"
                        options={[
                            { name: "Text", id: "text" },
                            { name: "Dropdown", id: "select" },
                        ]}
                        value={fieldType}
                        onChange={(e) => {
                            setFieldType(e.toString());
                            ourFieldMutation.mutate({
                                id: 1,
                                field: {
                                    ourFieldName: "test",
                                    theirFieldName: "test",
                                    used: 1,
                                },
                            });
                        }}
                    />
                </div>
            </ModalDialog>
        </>
    );
}
