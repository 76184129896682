import { Dispatch, SetStateAction, useEffect, useState } from "react";

import * as React from "react";
import { FolderIcon } from "@heroicons/react/24/outline";
import businessRuleTypeService from "../../../services/business-rule-type.service";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { BusinessRuleGroup } from "../../../typings/api/business-rule-group";
import processflowService from "../../../services/processflow.service";
import { Entry } from "../../../layout/add-to-list";
import EitherOrOption from "../../../layout/either-or-option";
import ModalDialog from "../../../layout/modal-dialog";
import Loader2 from "../../utilities/Loader2";
import InputControlled from "../../../layout/input-controlled";
import CheckBoxNeoGenControlled from "../../../layout/checkbox-controlled";
import _ from "lodash";
import withReactContent from "sweetalert2-react-content";
import { JsonViewer } from "@textea/json-viewer";
import businessRuleGroupService from "../../../services/business-rule-group.service";
import { ProcessFlow } from "../../../typings/api/processflow";
import processflowStageService from "../../../services/processflow-stage.service";
import { ProcessFlowStage } from "../../../typings/api/processflow-stage";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
const MySwal = withReactContent(Swal);

export type RuleType = {
    id?: number;
    name: string;
    icon?: string;
    url?: string;
};

enum ShowRuleType {
    All = 2,
    StepOnly = 1,
}

// const recent:RuleType[] = [];
// const enabled = [
//     { name: "Some Rule", icon: ShieldCheckIcon, shortcut: "N", url: "#" },
//     { name: "Some Other Rule", icon: ShieldCheckIcon, shortcut: "F", url: "#" },
// ];

function classNames(...classes: (string | boolean)[]) {
    return classes.filter(Boolean).join(" ");
}

function compare(a: any, b: any) {
    if ((a.name ?? "").toLowerCase() < (b.name ?? "").toLowerCase()) {
        return -1;
    }
    if ((a.name ?? "").toLowerCase() > (b.name ?? "").toLowerCase()) {
        return 1;
    }
    return 0;
}

const currentStepOptions = [
    { id: 1, title: "Current Step", description: "Show options from the current step" },
    { id: 2, title: "All Steps", description: "Show options from the entire product" },
];
const areOrOptions = [
    { id: 1, title: "All Must Match", description: "All enabled rule groups must match" },
    { id: 2, title: "At Least One", description: "If any of the enabled rule groups pass, everything passes" },
];

// A type
type RulePickerOptions = {
    show: boolean;
    close: () => void;
    processflowId: number;
    isStep: boolean;
    isStage: boolean;
    target: string;
    areOr: boolean;
    setAreOr: Dispatch<SetStateAction<boolean>>;
    targetEntry: Entry | ProcessFlow | ProcessFlowStage;
};

/**
 * A React component that renders a modal dialog that allows the user to select which rules they want to enable.
 * @param {RulePickerOptions} RulePickerOptions - The options for the RulePicker.
 * @returns {JsxElement} A React component that renders a modal dialog that allows the user to select which rules they want to enable.
 */
export default function RulePicker(options: RulePickerOptions): JSX.Element {
    const [query, setQuery] = useState("");
    const [enabled, setEnabled] = useState<BusinessRuleGroup[]>([]);
    const [currentStepOption, setCurrentStepOption] = useState(currentStepOptions[0]);
    const [areOrOption, setAreOrOption] = useState(areOrOptions[0]);
    const [areOr, setAreOr] = useState(false);
    const [initialSet, setInitialSet] = useState(false);
    const [filteredRules, setFilteredRules] = useState<BusinessRuleGroup[]>([]);
    const { processflowGroup } = useParams();
    const cache = useQueryClient();
    const allRulesAndGroupsQuery = useQuery(["business-rule-groups"], async () => {
        const response = await businessRuleGroupService.getAllIncludingChildren();
        if (response) {
            return response.data;
        }
    });

    /**
     * Gets all the process flows from the server.
     * @returns {Promise<ProcessFlow[]>}
     */
    const processFlowQuery = useQuery(["processFlows"], async () => {
        try {
            const response = await processflowService.getAll();
            if (response) {
                return response.data;
            } else {
                console.error("No process flows");
            }
        } catch (e) {
            console.error(e);
        }
    });

    const ruleTypeQuery = useQuery(["business-rule-types2"], async () => {
        const response = await businessRuleTypeService.getAll();
        if (response) {
            return response.data;
        } else {
            return [];
        }
    });

    /**
     * Gets all the stages for the given group id.
     *
     * @param {number} groupId - the group id to get the stages for.
     * @returns {Promise<ProcessFlowStage[]>} - the stages for the given group id.
     */
    const stageQuery = useQuery(["processflow-stages", "getAllByGroupId", processflowGroup], async () => {
        const response = await processflowStageService.getAllByGroupIdIncludingRuleGroups(Number(processflowGroup));
        if (response) {
            return response.data?.map((stage: ProcessFlowStage) => {
                return {
                    id: stage.id,
                    name: stage.name,
                    description: stage.description,
                    order: stage.order,
                    status: stage.status,
                    current: stage.current,
                    group: stage.group,
                    isPublic: stage.isPublic,
                    businessRuleGroups: stage.businessRuleGroups ?? [],
                };
            });
        }
    });

    /**
     * A custom hook that returns the process flow entries for the given group id.
     * This is the code that is being rewritten to pull entrees separately from the stages.
     *
     * @param {string} processflowGroup - the group id to get the process flow entries for.
     * @returns {ProcessFlow[]} - the process flow entries for the given group id.
     */
    const entriesQuery = useQuery(
        ["processflow-entries", processflowGroup],
        async () => {
            const response = await processflowService.getAllByGroupIdWithRuleGroupsAndRules(Number(processflowGroup));
            if (response) {
                // alert(JSON.stringify(response.data));
                const mapped = response.data
                    .map((entry: ProcessFlow) => {
                        const newEntry: any = {};
                        newEntry.id = entry.id;
                        newEntry.title = entry.title ?? "";
                        newEntry.businessRuleGroups = entry.businessRuleGroups ?? [];
                        newEntry.order = entry.order;
                        newEntry.stageName =
                            stageQuery.data?.find((stage: ProcessFlowStage) => stage.id === entry.stage)?.name ??
                            "Unknown";
                        return Object.assign(entry, newEntry);
                        // return newEntry;
                    })
                    .sort((a, b) => a.order - b.order);
                // alert(JSON.stringify(mapped));
                return mapped;
            } else {
                console.error(response);
            }
        },
        { enabled: stageQuery.isSuccess },
    );

    function removeItem(item: RuleType) {
        setEnabled(enabled.filter((e) => e.id !== item.id));
    }

    useEffect(() => {
        if (options.show) {
            setInitialSet(false);
        }
    }, [options.show]);

    useEffect(() => {
        // TODO: We need to set the initially enabled items
        if (!initialSet) {
            setInitialSet(true);
            if (options.isStep) {
                // Get the rules for the step
                const step = options.targetEntry as ProcessFlow;
                // if (!step) {
                // alert(JSON.stringify(options));
                // }
                setEnabled(step?.businessRuleGroups ?? []);
                setAreOr(step.ruleGroupsAreOr === 1);
                setAreOrOption(step.ruleGroupsAreOr ? areOrOptions[1] : areOrOptions[0]);
            } else if (options.isStage) {
                // Get the rules for the stage
                const stage = options.targetEntry as ProcessFlowStage;
                // console.error(stage);
                setEnabled(stage.businessRuleGroups ?? []);
                setAreOr(stage.ruleGroupsAreOr === 1);
                setAreOrOption(stage.ruleGroupsAreOr ? areOrOptions[1] : areOrOptions[0]);
            } else {
                // Get the rules for the product
                const field = options.targetEntry as Entry;
                // alert(JSON.stringify(field.ruleGroupsAreOr));
                setEnabled(field.ruleGroups ?? []);
                setAreOr(field.ruleGroupsAreOr);
                setAreOrOption(field.ruleGroupsAreOr ? areOrOptions[1] : areOrOptions[0]);
            }

            setAreOr(options.areOr);
            setCurrentStepOption(currentStepOptions[0]);
        }
    }, [enabled, initialSet, options, options.areOr, options.isStep, options.targetEntry]);

    useEffect(() => {
        setAreOr(areOrOption.id === 2);
        // alert(areOrOption.id === 2);
    }, [areOrOption]);

    useEffect(() => {
        let res;

        // TODO: make rules and existing have the same shape
        // const intrules: any[] = selectedOption.id === ShowRuleType.All ? rules.filter(ruleg => ruleg.businessRules?.some(rule => rule.processflowId === processflowId)) : existing;

        if (currentStepOption.id === ShowRuleType.StepOnly) {
            // If necessary, filter by process flow
            res = allRulesAndGroupsQuery.data?.filter((ruleGroup: BusinessRuleGroup) =>
                ruleGroup.businessRules?.some((rule) => rule.processflowId === options.processflowId),
            );
        } else {
            res = _.cloneDeep(allRulesAndGroupsQuery.data);
        }

        // Return sorted list

        // const intrules: any[] = selectedOption.id === 1 ? rules.filter(ruleg => ruleg.businessRules..processflowId = processflowId) : existing;
        // if (query === "") {
        //   // Make sure each entry is only there once
        //   res = intrules.filter((rule: any) => !enabled.find((enabledRule) => enabledRule.name === rule.name));
        // } else {
        //   res = intrules.filter((rule: any) => {
        //     return rule.name.toLowerCase().includes(query.toLowerCase()) && !(enabled.find(e => rule.id === e.id));
        //   });

        const filtered = res?.filter((ruleGroup: BusinessRuleGroup) => {
            return ruleGroup.name.toLowerCase().includes(query.toLowerCase());
        });
        filtered?.sort(compare);
        setFilteredRules(filtered ?? []);
    }, [allRulesAndGroupsQuery.data, enabled, options.processflowId, query, currentStepOption]);

    function saveRules() {
        // alert(JSON.stringify(options));
        // alert(JSON.stringify(enabled));
        // areOr
        // Check if we are a step or a field
        if (options.isStep || options.isStage) {
            // show saving swal
            MySwal.fire({
                title: "Saving rules",
                text: "Please wait...",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: false,
                showCancelButton: false,
                showCloseButton: false,
                // showLoaderOnConfirm: true,
                didOpen: async () => {
                    // Swal.showLoading();
                    // MySwal.showLoading("Confirm");
                    // entries.target ruleGroups = enabled;
                    let entry;
                    if (options.isStep) {
                        entry = entriesQuery.data?.find(
                            (entry: ProcessFlow) => Number(entry.id) === Number(options.processflowId),
                        );
                    } else {
                        entry = stageQuery.data?.find(
                            (entry: ProcessFlowStage) => Number(entry.id) === Number(options.processflowId),
                        );
                    }
                    //  = (options.isStep ? entriesQuery : stageQuery).data?.find(
                    //     (entry: ProcessFlow) => Number(entry.id) === Number(options.processflowId),
                    // );

                    const dbRules = [entry?.businessRuleGroups];
                    // const same = _.isEqual(dbRules[0], enabled);

                    // entriets.
                    const removals = _.differenceWith(dbRules[0], enabled, _.isEqual);
                    const additions = _.differenceWith(enabled, dbRules[0], _.isEqual);

                    // Remove processflow rule group entries

                    let endpoint = "/processflow-rule-groups";
                    if (options.isStage) {
                        // alert("Stage");
                        endpoint = "/processflow-stage-rule-groups";
                    }
                    console.error({ dbRules, removals, additions });

                    for (const removal of removals) {
                        // const objectToAlter = options.isStage ? "processflowId" : "processflowStageId";
                        processflowService
                            .getURL(
                                endpoint + "?filter=" + !options.isStage
                                    ? JSON.stringify({
                                          where: {
                                              processflowId: options.processflowId,
                                              businessRuleGroupId: removal.id,
                                          },
                                      })
                                    : JSON.stringify({
                                          where: {
                                              processflowStageId: options.processflowId,
                                              businessRuleGroupId: removal.id,
                                          },
                                      }),
                            )
                            .then((response) => {
                                if (response) {
                                    for (const item of response.data) {
                                        const newId = item.id;
                                        if (options.isStage) {
                                            processflowStageService.deleteURL(endpoint + "/" + newId);
                                        } else {
                                            processflowService.deleteURL(endpoint + "/" + newId);
                                        }
                                    }
                                }
                            });
                    }

                    // Add processflow rule group entries
                    for (const addition of additions) {
                        if (options.isStage) {
                            processflowStageService.postURL(endpoint, {
                                stageId: options.processflowId,
                                businessRuleGroupId: addition.id,
                            });
                        } else {
                            processflowService.postURL(endpoint, {
                                processflowId: options.processflowId,
                                businessRuleGroupId: addition.id,
                            });
                        }
                    }
                    // alert(1);
                    // Patch the processflow
                    if (options.isStage) {
                        processflowStageService.patchURL("/processflow-stages/" + options.processflowId, {
                            ruleGroupsAreOr: areOr ? 1 : 0,
                        });
                    } else {
                        await processflowService.patchURL(endpoint + "/" + options.processflowId, {
                            ruleGroupsAreOr: areOr ? 1 : 0,
                        });
                    }
                    //   options.close();
                    //   window.location.reload();
                    // });
                    // alert(2);
                    MySwal.close();
                    return false;
                },
            })
                .then(() => {
                    // alert("result");
                    // if (result.isConfirmed) {
                    Swal.fire({
                        title: "Rules saved",
                        icon: "success",
                        showConfirmButton: false,
                        timer: 1500,
                    }).then(() => {
                        options.close();
                        // window.location.reload();
                    });
                    // }
                })
                .catch((error) => {
                    console.error(error);
                });

            // const newStep: ProcessFlow = {
            // 	entries: JSON.stringify(value)
        } else {
            (options.targetEntry as Entry).ruleGroups = enabled;

            const allEntries = processFlowQuery.data?.find((pf) => pf.id === options.processflowId)?.entries;

            // Replace the target entry
            const newEntries = JSON.parse(allEntries ?? "[]")?.map((entry: Entry) => {
                if (entry.id === options.targetEntry.id) {
                    entry.ruleGroups = enabled;
                    entry.ruleGroupsAreOr = areOr;
                    return entry;
                } else {
                    entry.ruleGroupsAreOr = areOr;
                    return entry;
                }
            });

            // Patch the processflow
            processflowService
                .patchURL(processflowService.endpoint + "/" + options.processflowId, {
                    entries: JSON.stringify(newEntries),
                })
                .then((res) => {
                    cache.refetchQueries(["processFlow-entries"]);
                    options.close();
                });

            // alert(JSON.stringify(options));
        }
        // close();
    }

    function isRuleEnabled(ruleId: number) {
        return enabled.find((e) => e.id === ruleId);
    }

    function checkboxChanged(val: any, rule: BusinessRuleGroup) {
        if (val?.currentTarget.checked && !isRuleEnabled(rule.id ?? -1)) {
            setEnabled((e) => [...e, rule]);
        } else if (!val?.currentTarget.checked && isRuleEnabled(rule.id ?? -1)) {
            setEnabled((e) => e.filter((e2) => e2.id !== rule.id));
        }
        // const newEnabled = val?.currentTarget.checked ? [...enabled, rule] : enabled.filter((e) => e.id !== rule.id);
        // setEnabled(newEnabled);
    }

    return (
        <React.Fragment>
            <ModalDialog
                show={options.show}
                close={options.close}
                title={"Select Rule Groups for " + options.target}
                okText="Save"
                size="md"
                okAction={saveRules}
                showCancel={true}
            >
                {processFlowQuery.isLoading || ruleTypeQuery.isLoading ? (
                    <Loader2 />
                ) : (
                    <div className="h-[85vh] overflow-y-auto  ">
                        <EitherOrOption
                            options={currentStepOptions}
                            selectedOption={currentStepOption}
                            setSelectedOption={setCurrentStepOption}
                        />
                        <EitherOrOption
                            options={areOrOptions}
                            selectedOption={areOrOption}
                            setSelectedOption={setAreOrOption}
                        />
                        {/* {areOr?"1":"0"} */}
                        <InputControlled
                            value={query}
                            setValue={(e) => setQuery(e)}
                            placeholder="Search For a Rule"
                            label=""
                            className="mt-5"
                        />

                        {(query === "" || filteredRules.length > 0) && (
                            <>
                                <div className="grid grid-cols-2 gap-5 ">
                                    <span className="col-span-2 font-bold grey-3000 ">Existing Rule Groups</span>
                                    {enabled.map((rule) => (
                                        <div
                                            key={rule.id}
                                            className="flex items-center justify-between bg-blue-100 p-3 mb-2 rounded-lg shadow-lg border-2 border-white"
                                        >
                                            <div className="flex items-center">
                                                <div className="w-5 h-5 mr-2 text-green-500">
                                                    <i className="fal fa-scale-balanced"></i>
                                                </div>
                                                <div className="text-sm">{rule.name}</div>
                                            </div>
                                            <div
                                                className="w-5 h-5 cursor-pointer text-red-500"
                                                onClick={() => removeItem(rule)}
                                            >
                                                <i className="fas fa-times " />
                                            </div>
                                        </div>
                                    ))}

                                    <span className="col-span-2 ">Entries</span>

                                    {filteredRules.map((filteredRule: any) => {
                                        return (
                                            <span
                                                key={filteredRule.id}
                                                className={
                                                    " border-2 " +
                                                    (isRuleEnabled(filteredRule.id)
                                                        ? "border-red-300 bg-red-200 dark:border-red-900 dark:bg-red-900"
                                                        : " border-white bg-slate-50 dark:border-gray-900 dark:bg-slate-700") +
                                                    " rounded-lg shadow-lg p-3"
                                                }
                                            >
                                                <CheckBoxNeoGenControlled
                                                    name={filteredRule.name}
                                                    label={filteredRule.name}
                                                    description={filteredRule.description}
                                                    value={enabled.some((en) => en.id === filteredRule.id)}
                                                    setValue={(value: any) => {
                                                        checkboxChanged(value, filteredRule);
                                                        // enabled.push(filteredRule);
                                                    }}
                                                />
                                                {/* <FolderIcon
                          className={classNames(
                            "h-5 w-5 flex-shrink text-gray-900 dark:text-gray-500 text-opacity-40",
                          )}
                          aria-hidden="true"
                        />
                        <span className="ml-3 mb-2 truncate flex-grow text-sm align-baseline inline-block">{action.name}</span> */}

                                                <div className="inline-block m-1 bg-white dark:bg-gray-700 rounded-xl shadow-[0_35px_60px_-15px_rgba(0,0,0,0.1)]">
                                                    <JsonViewer
                                                        theme={"dark"}
                                                        rootName={false}
                                                        displayDataTypes={false}
                                                        defaultInspectDepth={0}
                                                        value={filteredRule}
                                                    />
                                                </div>
                                                {/* <PrintPre>{filteredRule}</PrintPre> */}
                                            </span>
                                        );
                                    })}
                                </div>

                                {query !== "" && filteredRules.length === 0 && (
                                    <div className="py-14 px-6 text-center sm:px-14">
                                        <FolderIcon
                                            className="mx-auto h-6 w-6 text-gray-900 text-opacity-40"
                                            aria-hidden="true"
                                        />
                                        <p className="mt-4 text-sm text-gray-900">
                                            We couldn&apos;t find any rules with that term. Please try again.
                                        </p>
                                    </div>
                                )}

                                {/* </Dialog.Panel> */}
                                {/* </Transition.Child> */}
                                {/* </div> */}
                            </>
                        )}
                    </div>
                )}
            </ModalDialog>
        </React.Fragment>
    );
}
